import API from '../api';
import qs from 'qs';

export default {
  generateReport(reportInfo) {
    const data = qs.stringify(reportInfo);

    return API.post('/reports', data)
      .then(response => {
        return response.data;
      });
  }
};
