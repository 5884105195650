<template>
  <div>
    <input-wrapper
      :label="label"
      :name="name"
      :required="required"
      :error="error"
    >
      <input
        v-model="value"
        :type="type"
        :name="name"
        :class="cssClass"
        :placeholder="placeholder"
        @keyup="handleUserInput"
      >
    </input-wrapper>
  </div>
</template>

<script>
import { FormControlMixin } from '../../mixins';
import { InputWrapper } from '../components';

export default {
  name: 'TextInput',
  components: { InputWrapper },
  mixins: [FormControlMixin],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    cssClass: {
      type: String,
      default: 'form-control'
    }
  }
};
</script>
