<script>
import { DateTimeUtils } from '../../../utils';

export default {
  name: 'DateTimeMixin',
  props: {
    lineItem: {
      type: Object,
      default: () => null
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatTime(dateString) {
      return DateTimeUtils.formatTime(dateString);
    },
    handleEdit() {
      this.$emitter.emit('modal.show', { from: 'editLineItemModal', lineItem: this.lineItem });
    },
    handleDelete() {
      this.$emitter.emit('modal.show', { from: 'deleteLineItemModal', entity: this.lineItem });
    }
  }
};
</script>
