<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row mb-4 search-form">
      <div class="col-12">
        <h4 class="pt-3 fs-6">
          Find a section:
        </h4>
      </div>
      <div class="col-sm-auto col-12">
        <div class="mb-3">
          <label
            for="queryString"
            class="form-label sr-only"
          >Section name</label>
          <input
            id="queryString"
            v-model="values.query"
            class="form-control"
            style="min-width: 250px"
            type="text"
            name="name"
            placeholder="Section name"
          >
        </div>
      </div>
      <div class="col-sm-auto col-12">
        <button
          class="btn btn-secondary"
          type="submit"
        >
          <i class="fa fa-search" />
          &nbsp;Search
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="resetSearch"
        >
          <i class="far fa-sync-alt" />
          &nbsp;Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: 'SectionSearchForm',
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      values: {
        query: ''
      }
    };
  },
  methods: {
    async onSubmit() {
      this.$emitter.emit('form.search', this.values);
    },
    resetSearch() {
      this.values.query = '';
    }
  }
};
</script>
