<template>
  <router-view />
</template>

<script>
import '@popperjs/core';
// import 'bootstrap/dist/js/bootstrap.min';

export default {};
</script>

<style lang="scss">

@import "./assets/scss/theme";

</style>
