<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row bg-white">
      <div class="sol-12">
        <h4 class="pt-3">
          Find a machine:
        </h4>
      </div>
      <div class="col-5">
        <div class="mb-3">
          <label
            for="queryString"
            class="form-label sr-only"
          >Machine name or serial number</label>
          <input
            id="queryString"
            v-model="values.query"
            class="form-control"
            type="text"
            name="name"
            placeholder="Machine name or serial number"
          >
        </div>
      </div>
      <div class="col-3">
        <div class="mb-3">
          <label
            for="machineSection"
            class="form-label sr-only"
          >Machine section</label>
          <select
            id="machineSection"
            v-model="values.section"
            class="form-select"
          >
            <option value="">
              Any Section
            </option>
            <option
              v-for="section in sections"
              :key="section.id"
              :value="section.id"
            >
              {{ section.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <button
          class="btn btn-secondary"
          type="submit"
        >
          <i class="fa fa-search" />
          &nbsp;Search
        </button>

        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="resetSearch"
        >
          <i class="far fa-sync-alt" />
          &nbsp;Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: 'MachineSearchForm',
  props: {
    machines: {
      type: Array,
      default: () => []
    },
    processes: {
      type: Array,
      default: () => []
    },
    operators: {
      type: Array,
      default: () => []
    },
    query: {
      type: Object,
      default: () => ({})
    },
    isLoadingSections: Boolean
  },
  data() {
    return {
      values: {
        query: '',
        section: ''
      }
    };
  },
  methods: {
    async onSubmit() {
      this.$emitter.emit('form.search', this.values);
    },
    resetSearch() {
      this.values = {
        query: '',
        section: ''
      };
    }
  }
};
</script>
