<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            Delete Time Entry
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p>
                Are you sure you want to delete this entry?
              </p>
            </div>
            <div class="col-12">
              <button
                type="button"
                class="btn btn-danger me-2"
                @click="deleteEntity"
              >
                Delete
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LineItemsAPI } from '../../../../api/modules';

export default {
  name: 'DeleteLineItemModal',
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    async deleteEntity() {
      try {
        const response = await LineItemsAPI.deleteLineItem(this.entity.id);

        if (response) {
          await this.$alert.success('Line item deleted');
        }

        if (response) {
          this.$emitter.emit('modal.delete', { from: 'deleteLineItemModal', entity: this.entity });
        }

      } catch {}
    }
  }
};
</script>

<style scoped>

</style>
