<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto">
        <h1 class="d-inline-block">
          Machines
        </h1>
      </div>

      <div class="col-sm-auto ms-auto col-12 text-center">
        <button
          class="btn btn-primary-green w-100 mx-md-0 mx-auto add-btn"
          style="min-width: 200px; max-width: 200px"
          @click="newModal.show()"
        >
          <i class="far fa-fw fa-1x fa-plus" />
          &nbsp;New Machine
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="exportMachines"
        >
          <i class="far fa-download" />
          &nbsp;Export
        </button>
      </div>
    </div>

    <machine-search-form
      :query="query"
      :sections="sections"
      :is-loading-sections="isLoading.sections"
    />

    <template v-if="isLoading.machines">
      <loading-animation text="Loading Machines..." />
    </template>

    <div v-else-if="!machines">
      <failed-to-load text="Failed to load Machines" />
    </div>

    <div v-else-if="!machines.length">
      <no-results text="There are no Machines">
        <button
          class="btn btn-outline-secondary"
          @click="newModal.show()"
        >
          Add entry <i class="far fa-plus ms-1" />
        </button>
      </no-results>
    </div>

    <template v-else>
      <template v-if="showForm">
        <template v-if="isLoading.sections">
          <loading-animation text="Loading Sections..." />
        </template>
      </template>

      <div class="row">
        <div class="col-12">
          <p class="d-sm-none d-block">
            Scroll Table <i class="far fa-arrow-long-right ms-1" />
          </p>
          <div class="table-responsive">
            <table
              class="table table-hover mg-table"
              style="min-width: 700px"
            >
              <thead>
                <tr>
                  <th scope="col">
                    Machine Number
                  </th>
                  <th scope="col">
                    Name
                  </th>
                  <th scope="col">
                    Section
                  </th>
                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                <template v-for="machine in machines">
                  <tr :key="machine.id">
                    <td>{{ machine.serialNumber }}</td>
                    <td>{{ machine.name }}</td>
                    <td>{{ machine.section.name }}</td>

                    <td class="text-end">
                      <button
                        class="btn btn-sm mx-1 btn-outline-secondary"
                        @click="entityToEdit = machine; editModal.show()"
                      >
                        <i class="fas fa-pen" />
                      </button>

                      <button
                        class="btn btn-sm btn-outline-warning"
                        @click="entityToArchiveId = machine.id; archiveModal.show();"
                      >
                        <i class="fas fa-archive" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <pagination
        v-show="totalCount > 0"
        :page="page"
        :total-count="totalCount"
        :items-per-page="itemsPerPage"
      />
    </template>

    <archive-machine-modal
      ref="archiveModal"
      :entity-id="entityToArchiveId"
    />

    <edit-machine-modal
      ref="editModal"
      :machine="entityToEdit"
      :sections="sections"
    />

    <new-machine-modal
      ref="newModal"
      :sections="sections"
    />

    <edit-machine-modal
      ref="editModal"
      :machine="entityToEdit"
      :sections="sections"
    />
  </div>
</template>

<script>
import { MachinesAPI, SectionsAPI } from '../../api/modules';
import { MachineSearchForm } from '../forms/search';
import {
  LoadingAnimation,
  NoResults,
  FailedToLoad,
  Pagination
} from '../shared';
import ArchiveMachineModal from '../shared/modals/archive/ArchiveMachineModal';
import { Modal } from '../../main';
import NewMachineModal from '../shared/modals/new/NewMachineModal';
import EditMachineModal from '../shared/modals/edit/EditMachineModal';
import { apiFetchItemsLimit } from '../../const';

export default {
  name: 'MachinePage',
  components: {
    EditMachineModal,
    NewMachineModal,
    ArchiveMachineModal,
    MachineSearchForm,
    Pagination,
    LoadingAnimation,
    NoResults,
    FailedToLoad
  },
  data() {
    return {
      machines: null,
      isLoading: {
        machines: true,
        sections: true
      },
      entityToEdit: null,
      sections: null,
      sectionId: null,
      showForm: false,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      searchForm: MachineSearchForm,
      entityToArchiveId: null,
      archiveModal: null,
      editModal: null,
      newModal: null
    };
  },
  async created() {
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.archive', this.modalArchiveHandler);
    this.$emitter.on('machine.add', this.machineCreated);
    this.$emitter.on('machine.edited', this.machineEdited);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.search', this.formSearch);

    await this.loadMachines();
    await this.loadSections();
  },
  mounted() {
    this.archiveModal = new Modal(this.$refs.archiveModal.$el, { focus: false });
    this.newModal = new Modal(this.$refs.newModal.$el, { focus: true });
    this.editModal = new Modal(this.$refs.editModal.$el, { focus: false });

    this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
      this.entityToEdit = null;

      this.$emitter.emit('modal.hidden', 'machinePage');
    });
    this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'machinePage');
    });
  },
  unmounted() {
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.archive', this.modalArchiveHandler);
    this.$emitter.off('machine.add', this.machineCreated);
    this.$emitter.off('machine.edited', this.machineEdited);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.search', this.formSearch);
  },
  methods: {
    async loadMachines() {
      this.isLoading.machines = true;
      try {
        const response = await MachinesAPI.getMachines(this.page, this.itemsPerPage, this.query);

        this.machines = response.data;
        this.totalCount = response.summary.countAll;

      } catch {}

      this.isLoading.machines = false;
    },
    async updateMachine(machine) {
      try {
        let machineToUpdate = JSON.parse(JSON.stringify(machine));

        machineToUpdate.section = this.sectionId;

        const response = await MachinesAPI.updateMachine(machineToUpdate);

        if (response) {
          let index = this.machines.findIndex(machine => machine.id === response.id);

          if (index !== -1) {
            this.machines[index] = response;
          }
        }
      } catch {}

      this.entityToEdit = null;
    },
    async deleteMachine(id) {
      await this.$alert.success('Machine archived');

      const index = this.machines.findIndex(machine => machine.id === id);

      if (index !== -1) {
        this.machines.splice(index, 1);
      }
    },
    machineEdited(machine) {
      const index = this.machines.findIndex(v => v.id === machine.id);

      if (index !== -1) {
        this.$set(this.machines, index, machine);
      }

      this.editModal.hide();
    },
    async loadSections() {
      try {
        const response = await SectionsAPI.getSections(1, apiFetchItemsLimit, {});

        this.sections = response.data;
      } catch {}

      this.isLoading.sections = false;
    },
    toggleEdit(machine) {
      this.entityToEdit = machine;
      this.sectionId = machine.section.id;
    },
    machineCreated() {
      this.newModal.hide();

      this.loadMachines();
    },
    async pageChanged(page) {
      this.page = page;
      await this.loadMachines();
    },
    async formSearch(values) {
      this.query = values;
      await this.loadMachines();
    },
    modalHideHandler(event) {
      if (event.from === 'archiveMachineModal') {
        this.archiveModal.hide();
      }
    },
    modalArchiveHandler(event) {
      if (event.from === 'archiveMachineModal') {
        this.deleteMachine(event.id);

        if (this.page > 1 && this.machines.length === 1) {
          this.page--;
        }

        this.loadMachines();
      }
    },
    async exportMachines() {
      const response = await MachinesAPI.exportMachines(this.query);

      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        link.download = 'Machines.xls';
        link.click();
      }
    }
  }
};

</script>
