<template>
  <div class="line-item-page-container">
    <div class="row align-items-center py-sm-3 flex-md-row flex-column-reverse">
      <div class="col-lg-auto col-md-auto col-sm-12 col-12 d-lg-block d-flex justify-content-md-start justify-content-center mb-md-0 mt-md-0 mb-2 mt-2">
        <div class="d-flex flex-wrap-reverse align-items-center justify-content-center">
          <div class="pe-2 me-2">
            <button
              class="btn btn-primary-white next-day-btn mb-md-0 mt-md-0 me-1 mt-2 mb-2"
              @click="changeDay(-1)"
            >
              <i class="fas fa-chevron-left" />
            </button>
            <button
              class="btn btn-primary-white next-day-btn mb-md-0 mt-md-0 mt-2 mb-2"
              :disabled="nextDayDisabled"
              @click="changeDay(1)"
            >
              <i class="fas fa-chevron-right" />
            </button>
          </div>
          <h3 class="d-inline-block pe-2 mb-md-0 mt-md-0 me-2 mb-2 mt-2">
            {{ getCurrentDateFormatted() }}
          </h3>
        </div>
      </div>

      <div class="col-lg-auto col-md-auto col-sm-12 col-12 d-flex ms-md-auto justify-content-md-end justify-content-center mb-md-0 mb-2">
        <div class="d-inline-block me-1">
          <button
            id="openCalendarButton"
            ref="picker"
            :class="{'focus': showDatePickerVariable }"
            class="btn btn-primary-white fs-5 py-1 px-2"
            @click="showDatePicker()"
          >
            <i class="far fa-fw fa-calendar-alt mx-0" />
          </button>
        </div>
        <div>
          <!-- // Date picker -->
          <div class="d-inline-block">
            <input
              v-show="showDatePickerVariable"
              id="datePickerInput"
              ref="calendar"
              class="form-control date-picker-input"
              type="text"
            >
          </div>
        </div>
        <div>
          <multi-select-input
            v-if="!isOperator"
            :options="operatorOptions"
            name="operator"
            placeholder="Select a operator"
            no-results-text="No operators found"
            no-options-text="No operators found"
          />
        </div>
      </div>
    </div>

    <div class="position-relative">
      <template v-if="operatorId !== 'all'">
        <div
          class="new-entry-container"
          style="flex-grow: 0"
        >
          <button
            class="btn btn-primary-green new-entry-btn"
            :disabled="!canAddEntry || isApiCallsLoading"
            :style="{ 'background-color': !canAddEntry ? 'gray' : '' }"
            @click="newModal ? newModal.show() : () => {}"
          >
            <span><i class="far fa-fw fa-3x fa-plus" /></span>
            <span class="fs-7">Add Entry</span>
          </button>
        </div>
      </template>

      <ul class="nav nav-tabs days-of-week-wrapper desktop nav-fill line-item-tabs d-flex">
        <li
          v-for="(dayOfWeekLabel, dayOfWeekValue) in daysOfWeek"
          :key="dayOfWeekValue"
          class="nav-item"
          @click="!isDayInFuture(dayOfWeekValue) && setDayOfWeek(dayOfWeekValue)"
        >
          <a
            class="nav-link"
            aria-current="page"
            :class="{ active: dayOfWeekValue === currentDate.getDay().toString(), 'disabled': isDayInFuture(dayOfWeekValue) }"
            href="#"
          >{{ dayOfWeekLabel }}</a>
        </li>
      </ul>

      <div class="days-of-week-wrapper mobile">
        <p
          class="text-center mt-1 mb-1 text-uppercase fs-7"
          style="letter-spacing: 2px"
        >
          <i class="far fa-calendar-day me-1" /> Day Selector
        </p>
        <select
          class="form-select py-3"
          @change="setDayOfWeekSelect($event)"
        >
          <option
            disabled
            selected
          >
            Select day
          </option>

          <option
            v-for="(dayOfWeekLabel, dayOfWeekValue) in daysOfWeek"
            :key="dayOfWeekValue"
            :value="dayOfWeekValue"
            :selected="dayOfWeekValue === currentDate.getDay().toString()"
          >
            {{ dayOfWeekLabel }}
          </option>
        </select>
      </div>

      <div class="pt-3 pb-2 mt-md-2 border-top">
        <template v-if="isLoading.lineItems">
          <loading-animation text="Loading Production sheet..." />
        </template>

        <template v-else-if="!lineItems">
          <failed-to-load text="Failed to load Time Entries" />
        </template>

        <template v-else-if="!lineItems.length">
          <no-results text="There are no time entries for this day">
            <template v-if="operatorId !== 'all'">
              <button
                class="btn btn-outline-secondary"
                :disabled="!canAddEntry || isApiCallsLoading"
                :style="{ 'background-color': !canAddEntry ? 'gray' : '' }"
                @click="newModal ? newModal.show() : () => {}"
              >
                Add entry <i class="far fa-plus ms-1" />
              </button>
            </template>
          </no-results>
        </template>

        <template v-else>
          <div
            v-for="lineItemGroup in lineItems"
            :key="lineItemGroup.operator.id"
            :class="{ 'mb-5': operatorId === 'all' }"
          >
            <template v-if="operatorId === 'all'">
              <h3>
                {{ lineItemGroup.operator.firstName }} {{ lineItemGroup.operator.lastName }}
              </h3>
            </template>

            <line-item
              v-for="lineItem in lineItemGroup.lineItems"
              :key="lineItem.id"
              :line-item="lineItem"
              :card-type="cardType"
            />
          </div>
        </template>
      </div>
    </div>

    <new-line-item-modal
      ref="newModal"
      :machines="machines"
      :processes="processes"
      :sections="sections"
      :parts="parts"
      :batch-numbers="batchNumbers"
      :current-date="currentDate"
      :operator-id="operatorId"
    />

    <edit-line-item-modal
      ref="editModal"
      :machines="machines"
      :processes="processes"
      :sections="sections"
      :parts="parts"
      :batch-numbers="batchNumbers"
      :line-item="entityToEdit"
      :current-date="currentDate"
      :operator-id="entityToEdit ? entityToEdit.operator.id : null"
    />

    <delete-line-item-modal
      ref="deleteModal"
      :entity="entityToDelete"
    />
  </div>
</template>

<script>
import {
  BatchNumbersAPI,
  LineItemsAPI,
  MachinesAPI,
  PartsAPI,
  ProcessesAPI,
  SectionsAPI,
  UsersAPI
} from '../../api/modules';
import {
  LineItem
} from '../line-items';
import { Modal } from '../../main';
import {
  LoadingAnimation,
  NoResults,
  FailedToLoad
} from '../shared';
import DeleteLineItemModal from '../shared/modals/delete/DeleteLineItemModal';
import NewLineItemModal from '../shared/modals/new/NewLineItemModal';
import { DateTime } from 'luxon';
import EditLineItemModal from '../shared/modals/edit/EditLineItemModal';
import datepicker from 'js-datepicker';
import { MultiSelectInput } from '../shared/forms/controls';
import { apiFetchItemsLimit } from '../../const';

export default {
  name: 'LineItemPage',
  components: {
    EditLineItemModal,
    NewLineItemModal,
    DeleteLineItemModal,
    LineItem,
    LoadingAnimation,
    NoResults,
    FailedToLoad,
    MultiSelectInput
  },
  data() {
    return {
      lineItems: [],
      isLoading: {
        lineItems: true,
        machines: true,
        operators: true,
        processes: true,
        sections: true,
        parts: true
      },
      entityToEdit: null,
      entityToDelete: null,
      machines: null,
      processes: null,
      sections: null,
      machineId: null,
      processId: null,
      operatorId: null,
      operators: null,
      batchNumbers: null,
      parts: null,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      newModal: null,
      deleteModal: null,
      currentDate: new Date(),
      daysOfWeek: {
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
        0: 'Sun'
      },
      showDatePickerVariable: false,
      editModal: null,
      canAddEntry: true,
      isVisible: false,
      filteredOperators: this.operators,
      cardType: 'desktop'
    };
  },
  computed: {
    profile() {
      return this.$store.getters['profile/getProfile'];
    },
    isOperator() {
      return this.profile && this.profile.roles !== 'Admin';
    },
    nextDayDisabled() {
      return this.currentDate.getDate() === new Date().getDate();
    },
    operatorOptions() {
      if (this.operators) {
        let operatorOptions = this.operators.map(operator => {
          return {
            value: operator.id,
            label: `${operator.firstName} ${operator.lastName}`,
            disabled: true
          };
        });

        return [{ label: 'All operators', value: 'all' }, ...operatorOptions];
      }

      return [];
    },
    isApiCallsLoading() {
      const { lineItems, machines, operators, parts, sections, processes } = this.isLoading;

      return lineItems || machines || operators || parts || sections || processes;
    }
  },
  watch: {
    '$store.state.profile.profile'() {
      const profile = this.$store.getters['profile/getProfile'];

      if (profile) {
        this.operatorId = profile.id;
      }
    },
    currentDate: {
      handler() {
        const threeDaysBack = new Date();

        threeDaysBack.setDate(threeDaysBack.getDate() - 4);

        if (this.currentDate > new Date()) {
          this.canAddEntry = false;
        } else this.canAddEntry = !(this.isOperator && this.currentDate < threeDaysBack);
      }
    },
    'window.innerWidth'() {

    }
  },
  async created() {
    this.$emitter.on('modal.show', this.modalShowHandler);
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.delete', this.modalDeleteHandler);
    this.$emitter.on('lineItem.add', this.lineItemCreated);
    this.$emitter.on('lineItem.edited', this.lineItemEdited);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.select.changed', this.formSelectChangedHandler);
    window.addEventListener('resize', this.windowResized);

    this.currentDate.setHours(0);
    this.currentDate.setMinutes(0);
    this.currentDate.setSeconds(0);

    await this.loadLineItems();

    if (this.profile && this.profile.roles === 'Admin') {
      await this.loadOperators();

      if (this.operators && this.operators.length && this.profile) {
        this.operatorId = this.profile.id;

        this.$emitter.emit('form.setFieldValue', {
          field: 'operator',
          value: this.operatorId
        });
      }
    }

    this.isLoading.operators = false;

    this.$emitter.emit('form.setFieldValue', { field: 'startTimeHours', value: '0' });
    this.$emitter.emit('form.setFieldValue', { field: 'startTimeMinutes', value: '0' });
    this.$emitter.emit('form.setFieldValue', { field: 'endTimeHours', value: '00' });
    this.$emitter.emit('form.setFieldValue', { field: 'endTimeMinutes', value: '00' });

    await this.loadMachines();
    await this.loadProcesses();
    await this.loadSections();
    await this.loadParts();
    await this.loadBatchNumbers();

    if (this.$refs.newModal && this.$refs.deleteModal && this.$refs.editModal) {
      this.newModal = new Modal(this.$refs.newModal.$el, { focus: false });
      this.deleteModal = new Modal(this.$refs.deleteModal.$el, { focus: false });
      this.editModal = new Modal(this.$refs.editModal.$el, { focus: false });

      this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
        this.entityToEdit = null;

        this.$emitter.emit('modal.hidden', 'lineItemPage');
      });
      this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
        this.$emitter.emit('modal.hidden', 'lineItemPage');
      });
    }
  },
  mounted() {
    // Date picker date selection
    if (this.$refs.calendar) {
      datepicker(this.$refs.calendar, {
        onSelect: (instance, date) => {
          this.showDatePickerVariable = !this.showDatePickerVariable;

          // Get date from picker and update date variable to said date
          if (new Date(date) > new Date()) {
            return;
          }

          this.currentDate = new Date(date);
          // Hide date picker

          this.loadLineItems();
        }
      });
    }

    document.addEventListener('click', e => {
      let dateSelector = document.querySelector('.qs-datepicker-container');

      if (this.showDatePickerVariable && !dateSelector.contains(e.target)) {
        setTimeout(() => {
          this.showDatePickerVariable = false;
        }, 1);
      }
    });
  },
  unmounted() {
    this.$emitter.off('modal.show', this.modalShowHandler);
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.delete', this.modalDeleteHandler);
    this.$emitter.off('lineItem.add', this.lineItemCreated);
    this.$emitter.off('lineItem.edited', this.lineItemEdited);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.select.changed', this.formSelectChangedHandler);
    window.removeEventListener('resize', this.windowResized);

  },
  methods: {
    setDayOfWeek(newDayOfWeek) {
      const currentDayOfWeek = this.currentDate.getDay();
      const difference = newDayOfWeek - currentDayOfWeek;

      if (difference !== 0) {
        this.currentDate.setDate(this.currentDate.getDate() + difference);

        this.currentDate = new Date(this.currentDate);

        this.loadLineItems();
      }
    },
    setDayOfWeekSelect(event) {
      let days = event.path[0];

      days.forEach(day => {
        if (day.selected) {
          let newDayOfWeek = day.value;

          const currentDayOfWeek = this.currentDate.getDay();
          const difference = newDayOfWeek - currentDayOfWeek;

          if (difference !== 0) {
            this.currentDate.setDate(this.currentDate.getDate() + difference);

            this.loadLineItems();
          }
        }
      });
    },
    showDatePicker() {
      setTimeout(() => {
        setTimeout(() => {
          this.$refs.calendar.focus();
        }, 1);
        this.showDatePickerVariable = !this.showDatePickerVariable;
      }, 1);
    },
    dateToString(date) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');
      const second = date.getSeconds().toString().padStart(2, '0');

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    async loadLineItems() {
      if (this.operatorId === 'all') {
        delete this.query.operator;
      } else {
        this.query.operator = this.operatorId || this.profile && this.profile.id;
      }

      const startTime = this.currentDate;
      const endTime = new Date(startTime.getTime());

      endTime.setHours(23);
      endTime.setMinutes(59);
      endTime.setSeconds(59);

      this.query.startTime = this.dateToString(startTime);
      this.query.endTime = this.dateToString(endTime);

      this.isLoading.lineItems = true;

      try {
        const response = await LineItemsAPI.getLineItems(this.page, this.itemsPerPage, this.query);

        this.lineItems = this.groupLineItems(response.data);
        this.totalCount = response.summary.countAll;

      } catch {}

      this.isLoading.lineItems = false;
    },
    groupLineItems(lineItems) {
      const groupedLineItems = [];

      lineItems.forEach(lineItem => {
        if (!groupedLineItems.find(v => v.operator.id === lineItem.operator.id)) {
          groupedLineItems.push({ operator: lineItem.operator, lineItems: [] });
        }

        const index = groupedLineItems.findIndex(item => item.operator.id === lineItem.operator.id);

        groupedLineItems[index].lineItems.push(lineItem);
      });

      return groupedLineItems;
    },
    async updateLineItem(lineItem) {
      try {
        let lineItemToUpdate = JSON.parse(JSON.stringify(lineItem));
        lineItemToUpdate.machine = this.machineId;
        // lineItemToUpdate.operator = this.operatorId;
        lineItemToUpdate.process = this.processId;

        const response = await MachinesAPI.updateMachine(lineItemToUpdate);

        if (response) {
          let index = this.machines.findIndex(lineItem => lineItem.id === response.id);

          if (index !== -1) {
            this.lineItems[index] = response;
          }
        }
      } catch {}

      this.entityToEdit = null;
    },
    async loadOperators() {
      try {
        const response = await UsersAPI.getUsers(1, apiFetchItemsLimit, {});

        this.operators = response.data;

      } catch {}
    },
    async loadParts() {
      try {
        const response = await PartsAPI.getParts(1, apiFetchItemsLimit, {});

        this.parts = response.data;
      } catch {}

      this.isLoading.parts = false;
    },
    async loadBatchNumbers() {
      try {
        const response = await BatchNumbersAPI.getBatchNumbers(1, apiFetchItemsLimit, {});

        this.batchNumbers = response.data;
      } catch {}

      this.isLoading.batchNumbers = false;
    },
    async loadMachines() {
      try {
        const response = await MachinesAPI.getMachines(1, apiFetchItemsLimit, {});
        this.machines = response.data;
      } catch {}

      this.isLoading.machines = false;
    },
    async loadProcesses() {
      try {
        const response = await ProcessesAPI.getProcesses(1, apiFetchItemsLimit, {});
        this.processes = response.data;
      } catch {}

      this.isLoading.processes = false;
    },
    async loadSections() {
      try {
        const response = await SectionsAPI.getSections(1, apiFetchItemsLimit, {});

        this.sections = response.data;
      } catch {}

      this.isLoading.sections = false;
    },
    lineItemCreated(lineItem) {
      const groupIndex = this.lineItems.findIndex(v => v.operator.id === lineItem.operator.id);

      if (groupIndex === -1) {
        const operator = this.operators ? this.operators.find(v => v.id === lineItem.operator.id) : this.$store.getters['profile/getProfile'];
        const group = {
          operator,
          lineItems: [lineItem]
        };

        this.$set(this.lineItems, 0, group);
      } else {
        const group = {
          operator: this.lineItems[groupIndex].operator,
          lineItems: [...this.lineItems[groupIndex].lineItems, lineItem]
        };

        this.$set(this.lineItems, groupIndex, group);
      }
    },
    modalShowHandler(event) {
      if (event.from === 'deleteLineItemModal') {
        this.entityToDelete = event.entity;
        this.deleteModal.show();
      } else if (event.from === 'editLineItemModal') {
        this.entityToEdit = event.lineItem;

        if (this.editModal) {
          this.editModal.show();
        }
      }
    },
    modalHideHandler(event) {
      if (event.source === 'newLineItemModal') {
        this.newModal.hide();
      }
    },
    modalDeleteHandler(event) {
      if (event.from === 'deleteLineItemModal') {
        const groupIndex = this.lineItems.findIndex(v => v.operator.id === event.entity.operator.id);

        if (groupIndex !== -1) {
          const lineItemIndex = this.lineItems[groupIndex].lineItems.findIndex(v => v.id === event.entity.id);

          this.lineItems[groupIndex].lineItems.splice(lineItemIndex, 1);

          if (this.lineItems[groupIndex].lineItems.length === 0) {
            this.lineItems.splice(groupIndex, 1);
          }
        }

        this.entityToDelete = null;
        this.deleteModal.hide();
      }
    },
    async pageChanged(page) {
      this.page = page;
      await this.loadLineItems();
    },
    changeDay(offset) {
      // this.currentDate.setHours(0);
      // this.currentDate.setMinutes(0);
      // this.currentDate.setSeconds(0);
      this.currentDate.setDate(this.currentDate.getDate() + offset);

      this.currentDate = new Date(this.currentDate);

      this.loadLineItems();
    },
    getCurrentDateFormatted() {
      const date = DateTime.fromJSDate(this.currentDate);
      const format = { month: 'short', weekday: 'long', day: 'numeric' };

      return date.setZone('Africa/Johannesburg').toLocaleString(format);
    },
    lineItemEdited(entity) {
      const groupIndex = this.lineItems.findIndex(v => v.operator.id === entity.operator.id);

      if (groupIndex !== -1) {
        const lineItemIndex = this.lineItems[groupIndex].lineItems.findIndex(v => v.id === entity.id);

        if (lineItemIndex !== -1) {
          this.lineItems[groupIndex].lineItems[lineItemIndex] = entity;

          const lineItemsGroup = this.lineItems[groupIndex];

          this.$set(this.lineItems, groupIndex, lineItemsGroup);
        }
      }

      this.editModal.hide();
    },
    isDayInFuture(dayOfWeekValue) {
      const newCurrentDate = new Date(this.currentDate);

      newCurrentDate.setDate(newCurrentDate.getDate() + (dayOfWeekValue - newCurrentDate.getDay()));

      return newCurrentDate.getTime() > new Date().getTime();
    },
    formSelectChangedHandler(event) {
      if (event.field === 'operator') {
        this.operatorId = event.value;
        this.loadLineItems();
      }
    },
    windowResized() {
      if (window.innerWidth <= 576) {
        this.cardType = 'mobile';
      } else if (window.innerWidth <= 768) {
        this.cardType = 'tablet';
      } else {
        this.cardType = 'desktop';
      }
    }
  }
};

</script>
