import API from '../api';

export default {
  getProfile() {
    return API.get('/me')
      .then(response => {
        return response.data;
      });
  }
};

