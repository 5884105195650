<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row mb-4 search-form">
      <div class="col-12">
        <h4 class="pt-3 fs-6">
          Find a process:
        </h4>
      </div>
      <div class="col-sm-auto col-12">
        <div class="mb-3">
          <label
            for="queryString"
            class="form-label sr-only"
          >Process name</label>
          <input
            id="queryString"
            v-model="values.query"
            class="form-control"
            style="min-width: 250px"
            type="text"
            name="name"
            placeholder="Process name"
          >
        </div>
      </div>
      <div class="col-sm-auto col-12">
        <div class="mb-3">
          <label
            for="processSection"
            class="form-label sr-only"
          >Process section</label>
          <multi-select-input
            :options="sectionOptions"
            name="section"
            placeholder="Select a section"
            no-results-text="No sections found"
            no-options-text="No sections found"
          />
        </div>
      </div>
      <div class="col-sm-auto col-12">
        <button
          class="btn btn-secondary"
          type="submit"
        >
          <i class="fa fa-search" />
          &nbsp;Search
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="resetSearch"
        >
          <i class="far fa-sync-alt" />
          &nbsp;Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import { MultiSelectInput } from '../../shared/forms/controls';

export default {
  name: 'ProcessSearchForm',
  components: {
    MultiSelectInput
  },
  props: {
    sections: {
      type: Array,
      default: () => []
    },
    query: {
      type: Object,
      default: () => ({})
    },
    isLoadingSections: Boolean
  },
  data() {
    return {
      values: {
        query: '',
        section: ''
      }
    };
  },
  computed: {
    sectionOptions() {
      if (this.sections) {
        return this.sections.map(section => {
          return {
            value: section.id,
            label: section.name
          };
        });
      }
      return [];
    }
  },
  created() {
    this.$emitter.on('form.select.changed', this.selectChangedHandler);
  },
  unmounted() {
    this.$emitter.off('form.select.changed', this.selectChangedHandler);
  },
  methods: {
    async onSubmit() {
      this.$emitter.emit('form.search', this.values);
    },
    resetSearch() {
      this.values = {
        query: '',
        section: ''
      };
      this.$emitter.emit('form.setFieldValue', {
        field: 'section',
        value: null
      });
    },
    setFieldValueHandler(event) {
      if (event.field === 'section') {
        this.values.section = event.value;
      }
    },
    selectChangedHandler(event) {
      if (event.field === 'section') {
        this.values.section = event.value;
      }
    }
  }
};
</script>
