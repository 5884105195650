<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto">
        <h1 class="d-inline-block">
          Users
        </h1>
      </div>

      <div class="col-sm-auto ms-auto col-12 text-center">
        <button
          class="btn btn-primary-green w-100 mx-md-0 mx-auto add-btn"
          style="min-width: 200px; max-width: 200px"
          @click="newModal.show()"
        >
          <i class="fas fa-fw fa-1x fa-plus" />
          &nbsp;New User
        </button>

        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="exportUsers"
        >
          <i class="far fa-download" />
          &nbsp;Export
        </button>
      </div>
    </div>

    <user-search-form :query="query" />

    <template v-if="isLoading.users">
      <loading-animation text="Loading Users..." />
    </template>

    <div v-else-if="!users">
      <failed-to-load text="Failed to load Users" />
    </div>

    <div v-else-if="!users.length">
      <no-results text="There are no Users">
        <button
          class="btn btn-outline-secondary"
          @click="newModal.show()"
        >
          Add entry <i class="far fa-plus ms-1" />
        </button>
      </no-results>
    </div>

    <template v-else>
      <template v-if="showForm">
        <loading-animation text="Loading Users..." />
      </template>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table
              class="table table-hover mg-table"
              style="min-width: 700px"
            >
              <thead>
                <tr>
                  <th scope="col">
                    Name
                  </th>
                  <th scope="col">
                    Email
                  </th>
                  <th scope="col">
                    Role
                  </th>
                  <th scope="col">
                    Active
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <template v-for="user in users">
                  <tr :key="user.id">
                    <td class="text-capitalize">
                      {{ user.firstName }} {{ user.lastName }}
                    </td>
                    <td>{{ user.username }}</td>
                    <td>{{ user.roles }}</td>
                    <td>
                      <div v-show="user.active">
                        <i class="fas fa-check fa-fw text-green" />
                      </div>
                      <div v-show="!user.active">
                        <i class="fas fa-times fa-fw text-danger" />
                      </div>
                    </td>

                    <td class="text-end">
                      <button
                        class="btn btn-sm btn-outline-secondary mx-1"
                        @click="toggleEdit(user)"
                      >
                        <i class="fas fa-fw fa-1x fa-pen" />
                      </button>

                      <button
                        class="btn btn-sm btn-outline-secondary mx-1"
                        @click="toggleChangeUserPassword(user)"
                      >
                        <i class="fas fa-fw fa-1x fa-key" />
                      </button>

                      <button
                        class="btn btn-sm btn-outline-warning"
                        @click="entityToArchiveId = user.id; archiveModal.show()"
                      >
                        <i class="fas fa-archive fa-fw fa-1x mx-1" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <pagination
        :page="page"
        :total-count="totalCount"
        :items-per-page="itemsPerPage"
      />
    </template>

    <archive-user-modal
      ref="archiveModal"
      :entity-id="entityToArchiveId"
    />

    <new-user-modal
      ref="newModal"
    />

    <edit-user-modal
      ref="editModal"
      :user="entityToEdit"
    />

    <change-user-password-modal
      ref="changeUserPasswordModal"
      :user="entityToEdit"
    />
  </div>
</template>

<script>
import { UsersAPI } from '../../api/modules';
import { LoadingAnimation, NoResults, FailedToLoad, Pagination } from '../shared';
import { UserSearchForm } from '../forms/search';
import { ArchiveUserModal } from '../shared/modals/archive';
import { Modal } from '../../main';
import { NewUserModal } from '../shared/modals/new';
import { EditUserModal } from '../shared/modals/edit';
import ChangeUserPasswordModal from '../shared/modals/ChangeUserPasswordModal';

export default {
  name: 'UserPage',
  components: {
    ChangeUserPasswordModal,
    NewUserModal,
    ArchiveUserModal,
    UserSearchForm,
    LoadingAnimation,
    NoResults,
    FailedToLoad,
    Pagination,
    EditUserModal
  },
  data() {
    return {
      users: null,
      isLoading: {
        users: true
      },
      entityToEdit: null,
      showForm: false,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      searchForm: UserSearchForm,
      archiveModal: null,
      newModal: null,
      editModal: null,
      changeUserPasswordModal: null,
      entityToArchiveId: null
    };
  },
  async created() {
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.archive', this.modalArchiveHandler);
    this.$emitter.on('user.add', this.userCreated);
    this.$emitter.on('user.edited', this.userEdited);
    this.$emitter.on('password.changed', this.passwordChanged);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.search', this.formSearch);

    await this.loadUsers();
  },
  mounted() {
    this.archiveModal = new Modal(this.$refs.archiveModal.$el);
    this.newModal = new Modal(this.$refs.newModal.$el);
    this.editModal = new Modal(this.$refs.editModal.$el);
    this.changeUserPasswordModal = new Modal(this.$refs.changeUserPasswordModal.$el);

    this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
      // this.entityToEdit = null;
      this.$emitter.emit('modal.hidden', 'userPage');
    });
    this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'userPage');
    });
    this.$refs.changeUserPasswordModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'userPage');
    });
  },
  unmounted() {
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.archive', this.modalArchiveHandler);
    this.$emitter.off('user.add', this.userCreated);
    this.$emitter.off('user.edited', this.userEdited);
    this.$emitter.off('password.changed', this.passwordChanged);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.search', this.formSearch);
  },
  methods: {
    async loadUsers() {
      this.isLoading.users = true;

      try {
        const response = await UsersAPI.getUsers(this.page, this.itemsPerPage, this.query);

        this.users = response.data;
        this.totalCount = response.summary.countAll;

      } catch {}

      this.isLoading.users = false;
    },
    async deleteUser(id) {
      try {
        const response = await UsersAPI.deleteUser(id);

        if (response) {
          await this.$alert.success('User archived');
        }

        const index = this.users.findIndex(user => user.id === id);

        this.users.splice(index, 1);
      } catch {}
    },
    async userEdited(user) {
      const index = this.users.findIndex(v => v.id === user.id);
      if (index !== -1) {
        this.$set(this.users, index, user);
        await this.$alert.success('User Edited');
      }

      this.editModal.hide();
    },
    toggleEdit(user) {
      this.entityToEdit = JSON.parse(JSON.stringify(user));

      this.editModal.show();
    },
    toggleChangeUserPassword(user) {
      this.entityToEdit = JSON.parse(JSON.stringify(user));

      this.changeUserPasswordModal.show();
    },
    passwordChanged() {
      this.changeUserPasswordModal.hide();
    },
    async userCreated() {
      this.newModal.hide();
      await this.$alert.success('User Created');

      await this.loadUsers();
    },
    async pageChanged(page) {
      this.page = page;

      await this.loadUsers();
    },
    async formSearch(values) {
      this.query = values;

      await this.loadUsers();
    },
    modalHideHandler(event) {
      if (event.from === 'archiveUserModal') {
        this.archiveModal.hide();
      }
    },
    modalArchiveHandler(event) {
      if (event.from === 'archiveUserModal') {
        this.deleteUser(event.id);

        if (this.page > 1 && this.users.length === 1) {
          this.page--;
        }

        this.loadUsers();
      }
    },
    async exportUsers() {
      const response = await UsersAPI.exportUsers(this.query);

      if (response) {
        const link = document.createElement('a');

        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        link.download = 'Users.xls';

        link.click();
      }
    }
  }
};
</script>
