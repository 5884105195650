<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Process Name <span class="text-danger">*</span>
          </label>
          <input
            v-model="process.name"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Process Name"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Process section <span class="text-danger">*</span>
          </label>

          <multi-select-input
            :options="sectionOptions"
            name="section"
            placeholder="Select a section"
            no-options-text="No sections found"
            no-results-text="No sections found"
            class="w-100"
          />
        </div>
      </div>

      <div class="col-12">
        <button
          class="btn btn-primary-green me-2"
          type="submit"
          :disabled="disabled"
        >
          <span v-html="buttonText" />
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ProcessesAPI } from '../../api/modules';
import MultiSelectInput from '../shared/forms/controls/MultiSelectInput';

export default {
  name: 'ProcessForm',
  components: { MultiSelectInput },
  props: {
    sections: {
      type: Array,
      default: () => []
    },
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      process: {
        name: '',
        section: 'default'
      }
    };
  },
  computed: {
    buttonText() {
      let buttonText;

      if (this.isSubmitting) {
        buttonText = this.entity ? 'Saving <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Adding <i class="fas fa-spin fa-spinner ms-2"></i>';
      } else {
        buttonText = this.entity ? 'Save <i class="fas fa-save ms-2"></i>' : 'Add <i class="fas fa-plus ms-2"></i>';
      }

      return buttonText;
    },
    disabled() {
      const { name, section } = this.process;

      return name === '' || section === '' || section === 'default';
    },
    sectionOptions() {
      if (!this.sections) {
        return [];
      }

      return this.sections.map(section => {
        return {
          value: section.id,
          label: section.name
        };
      });
    }
  },
  watch: {
    entity() {
      if (this.entity) {
        this.$emitter.emit('form.setFieldValue', { field: 'section', value: this.entity.section.id });

        this.process = JSON.parse(JSON.stringify(this.entity));
        this.process.section = this.process.section.id;
      }
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
    this.$emitter.on('form.select.changed', this.formSelectedChanged);
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
    this.$emitter.off('form.select.changed', this.formSelectedChanged);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      const apiMethod = this.entity
        ? () => ProcessesAPI.updateProcess(this.process)
        : () => ProcessesAPI.createProcess(this.process);
      const response = await apiMethod();

      if (response) {
        this.$emitter.emit(this.entity ? 'process.edited' : 'process.add', response);

        await this.$alert.success(this.entity ? 'Process edited' : 'Process added');
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.process.name = '';
      this.process.section = 'default';

      this.$emitter.emit('form.setFieldValue', {
        field: 'section',
        value: null
      });
    },
    modalHiddenHandler(source) {
      if (source === 'processPage') {
        this.resetForm();
      }
    },
    formSelectedChanged(event) {
      if (event.field === 'section') {
        this.process.section = event.value;
      }
    }
  }
};
</script>
