import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store';

import { ChangePassword, Login } from '../components/pages/auth';
import {
  Page,
  MachinePage,
  ProcessPage,
  LineItemPage,
  SectionPage,
  UserPage,
  BatchNumberPage,
  PartPage,
  ReportPage, NotFoundPage
} from '../components/pages';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'app_login',
    component: Login,
    meta: { title: 'Login' }
  },
  {
    path: '/change-password',
    name: 'app_change_password',
    component: ChangePassword,
    meta: { title: 'Change Your Password' }
  },
  {
    path: '/',
    component: Page,
    children: [
      {
        path: 'machines',
        name: 'app_machines',
        component: MachinePage,
        meta: { requiresAdmin: true, title: 'Machines' }
      },
      {
        path: 'processes',
        name: 'app_processes',
        component: ProcessPage,
        meta: { requiresAdmin: true, title: 'Processes' }
      },
      {
        path: 'sections',
        name: 'app_sections',
        component: SectionPage,
        meta: { requiresAdmin: true, title: 'Sections' }
      },
      {
        path: 'users',
        name: 'app_users',
        component: UserPage,
        meta: { requiresAdmin: true, title: 'Users' }
      },
      {
        path: 'line-items',
        name: 'app_line_items',
        component: LineItemPage,
        meta: { title: 'Line Items' }
      },
      {
        path: 'batch-numbers',
        name: 'app_batch_numbers',
        component: BatchNumberPage,
        meta: { requiresAdmin: true, title: 'Batch Numbers' }
      },
      {
        path: 'parts',
        name: 'app_parts',
        component: PartPage,
        meta: { requiresAdmin: true, title: 'Parts' }
      },
      {
        path: 'reports',
        name: 'app_reports',
        component: ReportPage,
        meta: { requiresAdmin: true, title: 'Reports' }
      }
    ]
  },
  {
    path: '*',
    name: 'app_not_found',
    component: NotFoundPage,
    meta: { title: 'Not Found' }
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.afterEach((to) => {
  document.title = `${to.meta.title} | Meshgear`;

  store.commit('alert/setType', null);
  store.commit('alert/setMessage', null);
});

export default router;
