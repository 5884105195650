<template>
  <section class="vh-100 bg-login">
    <div class="vh-100 login-container flex-shrink-0 p-5 bg-dark text-white w-25 position-relative">
      <div>
        <div class="text-center mb-3">
          <img
            alt="Mesh Gear logo"
            style="max-height: 60px"
            src="../../../assets/images/logo.png"
          >

          <div class="text-center my-6">
            <h1 class="fw-bold text-secondary fs-4 mt-3 mb-5">
              Production Sheet System
            </h1>
          </div>
        </div>

        <form
          id="login-form"
          class="mb-5"
          method="post"
          @submit.prevent="performLogin"
        >
          <div class="row">
            <div v-if="error">
              <p class="text-danger">
                {{ error }}
              </p>
            </div>

            <div class="col-12 mb-3">
              <label
                for="inputEmail"
                class="form-label"
              >Username</label>
              <input
                id="inputEmail"
                v-model="username"
                type="email"
                name="email"
                class="form-control"
                autocomplete="email"
                required
                autofocus
                @keypress="error = null"
              >
            </div>

            <div class="col-12 mb-4">
              <label
                for="inputPassword"
                class="form-label"
              >Password</label>
              <input
                id="inputPassword"
                v-model="password"
                type="password"
                name="password"
                class="form-control login-input"
                autocomplete="current-password"
                required
                @keypress="error = null"
              >
            </div>

            <div class="col-12 mb-3 text-center">
              <button
                form="login-form"
                class="w-100 btn btn-lg btn-primary-mg"
                type="submit"
                :disabled="loading || !canSubmit"
              >
                <template v-if="loading">
                  Signing in...
                </template>

                <template v-else>
                  Sign in
                </template>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex align-items-center justify-content-center mt-auto position-absolute bottom-0 start-50 translate-middle w-100">
        <div class="cl-logo">
          <img
            alt="Creation Labs Logo"
            src="../../../assets/images/cl-logo.png"
          >
        </div>
        <h6 class="text-white fs-7 text-opacity-50 fw-bold align-middle">
          Powered by <a
            class="text-white text-opacity-50"
            href="https://creationlabs.co.za/"
            target="_blank"
          >Creation Labs Software</a>
        </h6>
      </div>
    </div>
  </section>
</template>

<script>
import { SecurityAPI } from '../../../api/modules';

export default {
  name: 'Login',
  data() {
    return {
      username: process.env.NODE_ENV === 'development' ? 'byron.kyle@creationlabs.co.za' : '',
      password: process.env.NODE_ENV === 'development' ? 'admin123' : '',
      loading: false,
      error: null
    };
  },
  computed: {
    canSubmit() {
      return this.username !== '' && this.password !== '';
    }
  },
  methods: {
    async performLogin() {
      this.loading = true;
      this.submitted = 1;

      if (this.canSubmit) {
        try {
          const response = await SecurityAPI.login(this.username, this.password);

          if (response) {
            await this.$store.dispatch('security/setIsAuthenticated', true);
            await this.$store.dispatch('profile/setProfile', response.data);
            await this.$router.push({ name: 'app_line_items' });
          }
        } catch (err) {
          if (err.response) {
            this.error = err.response.data.error || err.response.data.detail;
          } else {
            this.error = 'An unknown error occured';
          }
        }
      }

      this.loading = false;
    }
  }
};

</script>

<style>

.bg-login {
  background-image: url('../../../assets/images/bg-login.jpg');
  background-size: cover;
  background-position: center;
}

.cl-logo img {
  margin-right: 10px;
  width: 50px;
}

</style>
