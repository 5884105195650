<template>
  <section class="vh-100 d-flex justify-content-center align-items-center">
    <div class="container text-center">
      <img
        style="height: 50px; width: auto; margin: auto"
        alt="Mesh Gear logo"
        src="../../assets/images/logo.png"
        class="d-block pr-3 mb-5 d-block"
      >
      <div
        class="card mx-auto w-50"
        style="min-width: 300px"
      >
        <div class="card-body">
          <i class="fas fa-cogs fa-3x mb-3 mt-4 mx-auto d-block text-center text-danger" />
          <h3 class="text-center">
            Whoops! Page Not Found.
          </h3>
          <router-link
            :to="{ name: 'app_line_items' }"
            class="btn btn-secondary mt-4"
          >
            <i class="far fa-arrow-left" /> Back to line items
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFoundPage'
};
</script>
