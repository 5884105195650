import { DateTime } from 'luxon';
export class DateTimeUtils{
  static formatTime(dateString) {
    if (!dateString) {
      return '';
    }

    const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd hh:mm:ss');

    return date.setLocale('za').toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}

