export const twentyFourHourTimeOptions = Array.from({ length: 24 }, (x, i) => i).map(v => (
  { value: v.toString(), label: v.toString().padStart(2, '0') }
));

export const fiveMinuteIntervalOptions = Array.from({ length: 59 }).map((v, i) => i).filter((v, i) => { return (i % 5 === 0); }).map(v => (
  { value: v.toString(), label: v.toString().padStart(2, '0') }
));

export const validationMessages = {
  required: 'This field is required',
  email: 'Please enter a valid email',
  number: 'Please enter a number'
};

export const apiFetchItemsLimit = process.env.VUE_APP_API_FETCH_ITEMS_LIMIT;
export const dropdownItemsLimit = process.env.VUE_APP_DROPDOWN_ITEMS_LIMIT;

console.log('api fetch items limit ', apiFetchItemsLimit);
console.log('dropdownItemsLimit ', apiFetchItemsLimit);
