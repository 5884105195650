<template>
  <div class="row">
    <div class="col-12 py-5">
      <div class="text-center">
        <p>
          {{ text }}
        </p>
        <div class="text-center">
          <i class="fas fa-fw fa-2x fa-cog fa-spin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    text: {
      type: String,
      default: 'Loading...'
    }
  }
};
</script>

<style scoped>

</style>