<template>
  <nav class="navbar navbar-expand-lg navbar-light p-3 mb-3 border-bottom bg-white">
    <!--    <header class="p-3 mb-3 border-bottom bg-white">-->
    <div class="menu-container container container-fluid">
      <div class="d-flex flex-wrap align-items-center justify-content-sm-center justify-content-lg-start w-100">
        <router-link
          :to="{ name: 'app_line_items' }"
          class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
        >
          <img
            style="height: 32px; width: auto;"
            alt="Mesh Gear logo"
            src="../../assets/images/logo.png"
            class="d-block pr-3 menu-logo-img"
          >
        </router-link>

        <div class="ms-sm-0 ms-auto">
          <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 d-sm-flex d-none">
            <li
              v-if="profile && profile.roles === 'Admin'"
              class="nav-item dropdown"
              :class="{'active': systemRoute }"
            >
              <a
                id="systemDropdown"
                class="nav-link dropdown-toggle link-dark"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                System
              </a>

              <ul
                id="systemDropdownMenu"
                ref="systemDropdownMenu"
                class="dropdown-menu"
                aria-labelledby="systemDropdown"
              >
                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_machine')}"
                    :to="{ name: 'app_machines' }"
                    class="dropdown-item"
                  >
                    Machines
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_process')}"
                    :to="{ name: 'app_processes' }"
                    class="dropdown-item"
                  >
                    Processes
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_section')}"
                    :to="{ name: 'app_sections' }"
                    class="dropdown-item"
                  >
                    Sections
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_user')}"
                    :to="{ name: 'app_users' }"
                    class="dropdown-item"
                  >
                    Users
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_batch')}"
                    :to="{ name: 'app_batch_numbers' }"
                    class="dropdown-item"
                  >
                    Batch Numbers
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="{'active': $route.name.startsWith('app_part')}"
                    :to="{ name: 'app_parts' }"
                    class="dropdown-item"
                  >
                    Parts
                  </router-link>
                </li>

                <template v-if="profile.roles === 'Admin'">
                  <li>
                    <router-link
                      :class="{'active': $route.name.startsWith('app_re')}"
                      :to="{ name: 'app_reports' }"
                      class="dropdown-item"
                    >
                      Reports
                    </router-link>
                  </li>
                </template>
              </ul>
            </li>

            <li>
              <router-link
                :class="{'active': $route.name.startsWith('app_line_item')}"
                class="nav-link px-2 link-dark"
                :to="{ name: 'app_line_items' }"
              >
                Time Tracking
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                id="navbarDropdown"
                class="nav-link dropdown-toggle link-dark"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-user" />&nbsp;
                {{ profile ? `${profile.firstName} ${profile.lastName}` : 'Loading' }}
              </a>

              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <router-link
                    :to="{ name: 'app_change_password' }"
                    class="dropdown-item"
                  >
                    Change Password
                  </router-link>
                </li>

                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    @click="logout"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <button
            ref="offcanvasNavbarButton"
            class="navbar-toggler nav-toggle-btn d-sm-none d-inline-block ms-auto"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="fs-6 me-2 nav-toggle-text">Menu</span>
            <span class="navbar-toggler-icon" />
          </button>
        </div>
      </div>

      <div
        id="offcanvasNavbar"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5
            id="offcanvasNavbarLabel"
            class="offcanvas-title"
          >
            Menu
          </h5>

          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>

        <div class="offcanvas-body">
          <ul class="off-canvas-nav justify-content-end flex-grow-1 pe-3 px-3">
            <li class="nav-item dropdown active text-white">
              <a
                id="systemDropdown"
                :class="{'active': systemRoute }"
                class="nav-link dropdown-toggle link-dark text-decoration-none"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                System
              </a>

              <ul
                ref="systemDropdownMenu"
                class="dropdown-menu"
                aria-labelledby="systemDropdown"
              >
                <li>
                  <router-link
                    :to="{ name: 'app_machines' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Machines
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'app_processes' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Processes
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'app_sections' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Sections
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'app_users' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Users
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'app_batch_numbers' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Batch Numbers
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'app_parts' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Parts
                  </router-link>
                </li>

                <template v-if="profile.roles === 'Admin'">
                  <li>
                    <router-link
                      :to="{ name: 'app_reports' }"
                      class="dropdown-item"
                      @click.native="closeOffcanvas"
                    >
                      Reports
                    </router-link>
                  </li>
                </template>
              </ul>
            </li>

            <li>
              <router-link
                class="nav-link link-dark"
                :to="{ name: 'app_line_items' }"
                @click.native="closeOffcanvas"
              >
                Time Tracking
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                id="navbarDropdown"
                class="nav-link dropdown-toggle link-dark"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-user" />&nbsp;
                {{ profile ? `${profile.firstName} ${profile.lastName}` : 'Loading' }}
              </a>

              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <router-link
                    :to="{ name: 'app_change_password' }"
                    class="dropdown-item"
                    @click.native="closeOffcanvas"
                  >
                    Change Password
                  </router-link>
                </li>

                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    @click="logout"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { SecurityAPI } from '../../api/modules';

export default {
  name: 'Navbar',
  computed: {
    profile() {
      return this.$store.getters['profile/getProfile'];
    },
    systemRoute() {
      return [
        'app_machines',
        'app_processes',
        'app_sections',
        'app_users',
        'app_batch_numbers',
        'app_parts',
        'app_reports'
      ].includes(this.$route.name);
    }
  },
  methods: {
    async logout() {
      try {
        if (this.$store.getters['security/isAuthenticated']) {
          await SecurityAPI.logout();
          await this.$store.dispatch('security/setIsAuthenticated', false);

          await this.$router.push({ name: 'app_login' });
        }
      } catch {}
    },
    closeOffcanvas() {
      this.$refs.offcanvasNavbarButton.click();
    }
  }
};
</script>
