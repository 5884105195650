import API from '../api';
import qs from 'qs';

export default {
  getParts(page, itemsPerPage, query) {
    let url = `/parts?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createPart(part) {
    const data = qs.stringify(part);

    return API.post('/parts/', data)
      .then(response => {
        return response.data;
      });
  },
  updatePart(part) {
    const data = qs.stringify(part);

    return API.put(`/parts/${part.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deletePart(id) {
    return API.delete(`/parts/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportParts(query) {
    let url = '/parts/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};
