<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row bg-white">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Password <span class="text-danger">*</span>
          </label>
          <input
            v-model="password"
            class="form-control"
            required
            type="password"
            name="password"
            placeholder="Password"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Confirm Password <span class="text-danger">*</span>
          </label>
          <input
            v-model="confirmPassword"
            class="form-control"
            required
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
          >
        </div>
      </div>
    </div>

    <div class="col-12">
      <input
        class="btn btn-primary-green me-2"
        type="submit"
        :value="buttonText"
        :disabled="disabled"
      >
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { UsersAPI } from '../../api/modules';

export default {
  name: 'UserForm',
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      password: '',
      confirmPassword: ''
    };
  },
  computed: {
    buttonText() {
      return this.isSubmitting ? 'Changing Password...' : 'Change Password';
    },
    disabled() {
      return this.password === '' || this.confirmPassword === '' || this.confirmPassword !== this.password;
    }
  },
  watch: {
    entity() {
      this.user = JSON.parse(JSON.stringify(this.entity));
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.user.plainPassword = this.password;

      const response = await UsersAPI.updateUser(this.user);

      if (response) {
        this.$emitter.emit('password.changed');

        await this.$alert.success('Password changed');
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.password = '';
      this.confirmPassword = '';
    },
    modalHiddenHandler(source) {
      if (source === 'changeUserPasswordPage') {
        this.resetForm();
      }
    }
  }
};
</script>
