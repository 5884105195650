import Vue from 'vue';
import mitt from 'mitt';
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min';

// import needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Import only needed icons from Font Awesome
import {
  faUtensils as fasUntensils,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faCog as fasCog,
  faCogs as fasCogs,
  faEdit as fasEdit,
  faTrash as fasTrash,
  faPen as fasPen,
  faPlus as fasPlus,
  faMinus as fasMinus,
  faSearch as fasSearch,
  faCheck as fasCheck,
  faArchive as fasArchive,
  faTimes as fasTimes,
  faExclamationCircle as fasExclamationCircle,
  faQuestionCircle as fasQuestionCircle,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faUser as fasUser,
  faCalendar as fasCalendar,
  faClock as fasClock,
  faSave as fasSave,
  faCalendarAlt as fasCalendarAlt,
  faKey as fasKey,
  faSpinner as fasSpinner
} from '@fortawesome/free-solid-svg-icons';

// Regular Icons
import {
  faSyncAlt as farSyncAlt,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faUtensils as farUntensils,
  faCog as farCog,
  faCogs as farCogs,
  faEdit as farEdit,
  faTrash as farTrash,
  faPen as farPen,
  faPlus as farPlus,
  faMinus as farMinus,
  faSearch as farSearch,
  faCheck as farCheck,
  faArchive as farArchive,
  faTimes as farTimes,
  faExclamationCircle as farExclamationCircle,
  faQuestionCircle as farQuestionCircle,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faUser as farUser,
  faCalendar as farCalendar,
  faClock as farClock,
  faSave as farSave,
  faCalendarAlt as farCalendarAlt,
  faCalendarDay as farCalendarDay,
  faDownload as farDownload
} from '@fortawesome/pro-regular-svg-icons';

// Light Icons
import {
  faUtensils as falUntensils,
  faCog as falCog,
  faEdit as falEdit,
  faTrash as falTrash,
  faPen as falPen,
  faPlus as falPlus,
  faMinus as falMinus,
  faSearch as falSearch,
  faCheck as falCheck,
  faArchive as falArchive,
  faTimes as falTimes,
  faExclamationCircle as falExclamationCircle,
  faQuestionCircle as falQuestionCircle,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faUser as falUser,
  faCalendar as falCalendar,
  faClock as falClock
} from '@fortawesome/pro-light-svg-icons';

// add the imported icons to the library
library.add(
  // Solid
  fasUntensils,
  farArrowLeft,
  farArrowRight,
  fasArrowLeft,
  fasArrowRight,
  fasCog,
  fasCogs,
  fasEdit,
  fasTrash,
  fasPen,
  fasPlus,
  fasMinus,
  fasSearch,
  fasCheck,
  fasArchive,
  fasTimes,
  fasExclamationCircle,
  fasQuestionCircle,
  fasChevronLeft,
  fasChevronRight,
  fasUser,
  fasCalendar,
  fasClock,
  fasSave,
  fasCalendarAlt,
  fasKey,
  fasSpinner,
  // Regular
  farSyncAlt,
  farUntensils,
  farCog,
  farCogs,
  farEdit,
  farTrash,
  farPen,
  farPlus,
  farSearch,
  farCheck,
  farArchive,
  farMinus,
  farTimes,
  farExclamationCircle,
  farQuestionCircle,
  farUser,
  farChevronLeft,
  farChevronRight,
  farCalendar,
  farClock,
  farSave,
  farCalendarAlt,
  farCalendarDay,
  farDownload,
  // Light
  falUntensils,
  falCog,
  falEdit,
  falTrash,
  falPen,
  falPlus,
  falSearch,
  falCheck,
  falArchive,
  falMinus,
  falTimes,
  falExclamationCircle,
  falQuestionCircle,
  falUser,
  falChevronLeft,
  falChevronRight,
  falCalendar,
  falClock
);

import App from './App.vue';
import { alert } from './plugins';
import router from './router';
import store from './store';

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

Vue.config.productionTip = false;

const eventEmitter = mitt();

const app = new Vue({
  router,
  store,
  render: h => h(App)
});

Vue.prototype.$emitter = eventEmitter;

Vue.use(alert);

app.$mount('#app');

export { Modal };
