<template>
  <component
    :is="lineItemCardComponent"
    :line-item="lineItem"
    :is-editing="isEditing"
  />
</template>

<script>
import { DateTime } from 'luxon';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min';
import { LineItemsAPI } from '../../api/modules';
import {
  LineItemMobileCard,
  LineItemTabletCard,
  LineItemDesktopCard
} from './line-item-cards';
import { DateTimeUtils } from '../../utils';

export default {
  name: 'LineItem',
  components: { LineItemMobileCard },
  props: {
    lineItem: {
      type: Object,
      default: () => null
    },
    cardType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    lineItemCardComponent() {
      return {
        mobile: LineItemMobileCard,
        tablet: LineItemTabletCard,
        desktop: LineItemDesktopCard
      }[this.cardType];
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.lunchTooltip) {
        new Tooltip(this.$refs.lunchTooltip);
      }
    }, 100);
  },
  unmounted() {
    this.$emitter.off('lineItem.edit', this.showEditModal);
    this.$emitter.off('lineItem.delete', this.showDeleteModal);
  },
  created() {
    this.$emitter.on('lineItem.edit', this.showEditModal);
    this.$emitter.on('lineItem.delete', this.showDeleteModal);
  },
  methods: {
    formatTime(dateString) {
      return DateTimeUtils.formatTime(dateString);
    },
    formatDate(dateString) {
      const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd hh:mm:ss');
      const format = { month: 'short', weekday: 'short', day: 'numeric' };
      return date.setZone('Africa/Johannesburg').toLocaleString(format);
    },
    handleEdit() {
      this.$emitter.emit('modal.show', { from: 'editLineItemModal', lineItem: this.lineItem });
    },
    handleDelete() {
      this.$emitter.emit('modal.show', { from: 'deleteLineItemModal', entity: this.lineItem });
    },
    showEditModal() {
      // this.entityToEdit = lineItem;
      // this.editModal.show();
    },
    showDeleteModal() {
      // this.entityToDelete = lineItem;
      // this.deleteModal.show();
    },
    toggleEdit(lineItem) {
      this.entityToEdit = lineItem;
      this.operatorId = lineItem.operator.id;
      this.machineId = lineItem.machine.id;
      this.processId = lineItem.process.id;
    },
    async updateLineItem() {
      try {
        await LineItemsAPI.updateLineItem(this.lineItem);
      } catch (err) {
        console.log(err);
      }

      this.entityToEdit = null;
    }
  }
};
</script>
