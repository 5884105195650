<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            Archive Batch Number
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p>
                Are you sure you want to archive this batch number?
              </p>
            </div>

            <div class="col-12">
              <button
                type="button"
                class="btn btn-warning me-2"
                @click="archiveEntity"
              >
                Archive
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BatchNumbersAPI } from '../../../../api/modules';

export default {
  name: 'ArchiveBatchNumberModal',
  props: {
    entityId: {
      type: String,
      default: null
    }
  },
  methods: {
    async archiveEntity() {
      try {
        const response = await BatchNumbersAPI.deleteBatchNumber(this.entityId);

        if (response) {
          this.$emitter.emit('modal.archive', { from: 'archiveBatchNumberModal', id: this.entityId });
        }

        this.$emitter.emit('modal.hide', { from: 'archiveBatchNumberModal' });
      } catch {}
    }
  }
};
</script>
