import qs from 'qs';

import API from '../api';

export default {
  getMachines(page, itemsPerPage, query) {
    let url = `/machines?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createMachine(machine) {
    const data = qs.stringify(machine);

    return API.post('/machines/', data)
      .then(response => {
        return response.data;
      });
  },
  updateMachine(machine) {
    const data = qs.stringify(machine);

    return API.put(`/machines/${machine.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteMachine(id) {
    return API.delete(`/machines/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportMachines(query) {
    let url = '/machines/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};

