<template>
  <multiselect
    v-model="value"
    :options="options"
    label="label"
    v-bind="$attrs"
    :placeholder="placeholder"
    :options-limit="optionsLimit"
    @input="handleInput"
  >
    <template #tag="{ option }">
      <div
        class="multiselect-tag"
      >
        {{ option.label }}
      </div>
    </template>

    <template #noResult>
      <span>{{ noResultsText }}</span>
    </template>

    <template #noOptions>
      <span>{{ noOptionsText }}</span>
    </template>

    <template #singleLabel="{ option }">
      {{ getLabel(option) }}
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { FormControlMixin } from '../../mixins';
import { dropdownItemsLimit } from '../../../../const';

export default {
  name: 'MultiSelectInput',
  components: {
    Multiselect
  },
  mixins: [FormControlMixin],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    noResultsText: {
      type: String,
      default: 'No results found'
    },
    noOptionsText: {
      type: String,
      default: 'No results found'
    },
    default: {
      type: String,
      default: null
    },
    optionsLimit: {
      type: Number,
      default: Number(dropdownItemsLimit)
    }
  },
  methods: {
    handleInput(option) {
      this.value = option.value;

      const event = { field: this.name, value: this.value };

      this.$emitter.emit('form.select.changed', event);
      this.$emitter.emit('form.setFieldValue', event);
      this.$emitter.emit('form.validateField', { field: event.field });
    },
    getLabel(id) {
      const option = this.options.find(option => option.value === id);

      return option ? option.label : '';
    }
  }
};
</script>
