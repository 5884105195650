<script>
export default {
  name: 'FormControlMixin',
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    formClass: {
      type: String,
      default: 'form-control'
    }
  },
  data() {
    return {
      value: null,
      error: null
    };
  },
  created() {
    this.$emitter.on('form.setFieldValue', this.setFieldValueHandler);
    this.$emitter.on('form.setFieldError', this.setFieldErrorHandler);
  },
  unmounted() {
    this.$emitter.off('form.setFieldValue', this.setFieldValueHandler);
    this.$emitter.off('form.setFieldError', this.setFieldErrorHandler);
  },
  methods: {
    setFieldValueHandler(event) {
      if (event.field === this.name) {
        this.value = event.value;
      }
    },
    setFieldErrorHandler(event) {
      if (event.field === this.name) {
        this.error = event.message;
      }
    },
    handleUserInput(e) {
      let value;

      switch (e.target.type) {
        case 'text':
          value = e.target.value;

          break;
        case 'checkbox':
          value = e.target.checked;

          break;
      }

      this.value = value;

      this.$emitter.emit('form.setFieldValue', { field: this.name, value });
      this.$emitter.emit('form.validateField', { field: this.name });
    }
  }
};
</script>
