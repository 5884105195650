<template>
  <form @submit.prevent="onSubmit">
    <h2 class="mb-4">
      Change Your Password
    </h2>

    <template v-if="error">
      <span class="text-danger">{{ error }}</span>
    </template>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Current Password <span class="text-danger">*</span>
          </label>
          <input
            v-model="oldPassword"
            class="form-control"
            required
            type="password"
            name="oldPassword"
            placeholder="Current Password"
          >
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            New Password <span class="text-danger">*</span>
          </label>
          <input
            v-model="password"
            class="form-control"
            required
            type="password"
            name="newPassword"
            placeholder="New Password"
          >
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Confirm Password <span class="text-danger">*</span>
          </label>
          <input
            v-model="confirmPassword"
            class="form-control"
            required
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
          >
        </div>
      </div>
    </div>

    <div class="col-12">
      <input
        class="btn btn-primary-green me-2"
        type="submit"
        :value="isSubmitting ? 'Saving...' : 'Save'"
        :disabled="disabled"
      >
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { SecurityAPI } from '../../api/modules';

export default {
  name: 'ChangePasswordForm',
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSubmitting: false,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      error: null
    };
  },
  computed: {
    disabled() {
      return this.oldPassword === '' || this.password === '' || this.confirmPassword === '' || this.confirmPassword !== this.password;
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      try {
        const response = await SecurityAPI.changePassword(this.oldPassword, this.password);

        if (response) {
          await this.$router.push({ name: 'app_line_items' });
        }
      } catch (err) {
        this.error = err.response.data.detail;
      }

      this.isSubmitting = false;
    }
  }
};
</script>
