<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            Edit User
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div class="modal-body">
          <user-form :entity="user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserForm } from '../../../forms';

export default {
  name: 'EditLineItemModal',
  components: { UserForm },
  props: {
    user: {
      type: Object,
      default: () => null
    }
  }
};
</script>
