<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto">
        <h1 class="d-inline-block">
          Sections
        </h1>
      </div>

      <div class="col-sm-auto ms-auto col-12 text-center">
        <button
          class="btn btn-primary-green w-100 mx-md-0 mx-auto add-btn"
          style="min-width: 200px; max-width: 200px"
          @click="newModal.show()"
        >
          <i class="fas fa-fw fa-1x fa-plus" />
          &nbsp;New Section
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="exportSections"
        >
          <i class="far fa-download" />
          &nbsp;Export
        </button>
      </div>
    </div>

    <section-search-form
      :query="query"
    />

    <template v-if="isLoading">
      <loading-animation text="Loading Sections..." />
    </template>

    <div v-else-if="!sections">
      <failed-to-load text="Failed to load Sections" />
    </div>

    <div v-else-if="!sections.length">
      <no-results text="There are no Sections">
        <button
          class="btn btn-outline-secondary"
          @click="newModal.show()"
        >
          Add entry <i class="far fa-plus ms-1" />
        </button>
      </no-results>
    </div>

    <template v-else>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table
              class="table table-hover mg-table"
              style="min-width: 250px"
            >
              <thead>
                <tr>
                  <th scope="col">
                    Name
                  </th>

                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                <template v-for="section in sections">
                  <tr :key="section.id">
                    <td>{{ section.name }}</td>

                    <td class="text-end">
                      <button
                        class="btn btn-sm btn-outline-secondary mx-1"
                        @click="toggleEdit(section)"
                      >
                        <i class="fas fa-fw fa-1x fa-pen" />
                      </button>

                      <button
                        class="btn btn-sm btn-outline-warning"
                        @click="entityToArchiveId = section.id; archiveModal.show()"
                      >
                        <i class="fas fa-archive fa-fw fa-1x mx-1" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <pagination
          v-show="totalCount > 0"
          :page="page"
          :total-count="totalCount"
          :items-per-page="itemsPerPage"
        />
      </div>
    </template>

    <archive-section-modal
      ref="archiveModal"
      :entity-id="entityToArchiveId"
    />

    <new-section-modal ref="newModal" />

    <edit-section-modal
      ref="editModal"
      :section="entityToEdit"
    />

    <edit-section-modal
      ref="editModal"
      :section="entityToEdit"
    />
  </div>
</template>

<script>
import { SectionsAPI } from '../../api/modules';
import { SectionSearchForm } from '../forms/search';
import {
  Pagination,
  LoadingAnimation,
  NoResults,
  FailedToLoad
} from '../shared';
import { Modal } from '../../main';
import ArchiveSectionModal from '../shared/modals/archive/ArchiveSectionModal';
import NewSectionModal from '../shared/modals/new/NewSectionModal';
import EditSectionModal from '../shared/modals/edit/EditSectionModal';

export default {
  name: 'SectionPage',
  components: {
    EditSectionModal,
    NewSectionModal,
    ArchiveSectionModal,
    FailedToLoad,
    NoResults,
    SectionSearchForm,
    Pagination,
    LoadingAnimation
  },
  data() {
    return {
      sections: null,
      isLoading: true,
      entityToEdit: null,
      showForm: false,
      sectionId: null,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      searchForm: SectionSearchForm,
      archiveModal: null,
      newModal: null,
      editModal: null,
      entityToArchiveId: null
    };
  },
  async created() {
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.archive', this.modalArchiveHandler);
    this.$emitter.on('section.add', this.sectionCreated);
    this.$emitter.on('section.edited', this.sectionEdited);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.search', this.formSearch);

    await this.loadSections();

    this.isLoading = false;
  },
  mounted() {
    this.archiveModal = new Modal(this.$refs.archiveModal.$el, { focus: false });
    this.newModal = new Modal(this.$refs.newModal.$el, { focus: false });
    this.editModal = new Modal(this.$refs.editModal.$el, { focus: false });

    this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
      this.entityToEdit = null;

      this.$emitter.emit('modal.hidden', 'sectionPage');
    });
    this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'sectionPage');
    });
  },
  unmounted() {
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.archive', this.modalArchiveHandler);
    this.$emitter.off('section.add', this.sectionCreated);
    this.$emitter.off('section.edited', this.sectionEdited);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.search', this.formSearch);
  },
  methods: {
    async loadSections() {
      try {
        const response = await SectionsAPI.getSections(this.page, this.itemsPerPage, this.query);

        this.sections = response.data;
        this.totalCount = response.summary.countAll;
      } catch {}
    },
    async updateSection(section) {
      try {
        let sectionToUpdate = JSON.parse(JSON.stringify(section));

        const response = await SectionsAPI.updateSection(sectionToUpdate);

        if (response) {
          let index = this.sections.findIndex(section => section.id === response.id);

          if (index !== -1) {
            this.sections[index] = response;
          }
        }
      } catch {}

      this.entityToEdit = null;
    },
    async deleteSection(id) {
      try {
        const response = await SectionsAPI.deleteSection(id);

        if (response) {
          await this.$alert.success('Section archived');
        }

        const index = this.sections.findIndex(section => section.id === id);
        this.sections.splice(index, 1);

      } catch {}
    },
    sectionEdited(section) {
      const index = this.sections.findIndex(v => v.id === section.id);

      if (index !== -1) {
        this.$set(this.sections, index, section);
      }

      this.editModal.hide();
    },
    toggleEdit(section) {
      this.entityToEdit = section;

      this.editModal.show();
    },
    sectionCreated() {
      this.newModal.hide();
      this.loadSections();
    },
    async pageChanged(page) {
      this.page = page;

      await this.loadSections();
    },
    async formSearch(values) {
      this.query = values;

      await this.loadSections();
    },
    modalHideHandler(event) {
      if (event.from === 'archiveSectionModal') {
        this.archiveModal.hide();
      }
    },
    modalArchiveHandler(event) {
      if (event.from === 'archiveSectionModal') {
        this.deleteSection(event.id);

        if (this.page > 1 && this.sections.length === 1) {
          this.page--;
        }

        this.loadSections();
      }
    },
    async exportSections() {
      const response = await SectionsAPI.exportSections(this.query);

      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        link.download = 'Sections.xls';
        link.click();
      }
    }
  }
};

</script>
