import API from '../api';
import qs from 'qs';

export default {
  getSections(page, itemsPerPage, query) {
    let url = `/sections?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createSection(section) {
    const data = qs.stringify(section);

    return API.post('/sections/', data)
      .then(response => {
        return response.data;
      });
  },
  updateSection(section) {
    const data = qs.stringify(section);

    return API.put(`/sections/${section.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteSection(id) {
    return API.delete(`/sections/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportSections(query) {
    let url = '/sections/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};

