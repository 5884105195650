<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto">
        <h1 class="d-inline-block">
          Processes
        </h1>
      </div>

      <div class="col-sm-auto ms-auto col-12 text-center">
        <button
          class="btn btn-primary-green w-100 mx-md-0 mx-auto add-btn"
          style="min-width: 200px; max-width: 200px"
          @click="newModal.show()"
        >
          <i class="fas fa-fw fa-1x fa-plus" />
          &nbsp;New Process
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="exportProcesses"
        >
          <i class="far fa-download" />
          &nbsp;Export
        </button>
      </div>
    </div>

    <process-search-form
      :query="query"
      :sections="sections"
      :is-loading-sections="isLoading.sections"
    />

    <template v-if="isLoading.processes">
      <loading-animation text="Loading Processes..." />
    </template>

    <div v-else-if="!processes">
      <failed-to-load text="Failed to load Processes" />
    </div>

    <div v-else-if="!processes.length">
      <no-results text="There are no Processes">
        <button
          class="btn btn-outline-secondary"
          @click="newModal.show()"
        >
          Add entry <i class="far fa-plus ms-1" />
        </button>
      </no-results>
    </div>

    <template v-else>
      <template v-if="showForm">
        <template v-if="isLoading.sections">
          <loading-animation text="Loading Sections..." />
        </template>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table
              class="table table-hover mg-table"
              style="min-width: 700px"
            >
              <thead>
                <tr>
                  <th scope="col">
                    Name
                  </th>
                  <th>
                    Section
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <template v-for="process in processes">
                  <tr :key="process.id">
                    <td>{{ process.name }}</td>
                    <td>{{ process.section.name }}</td>

                    <td class="text-end">
                      <button
                        class="btn btn-sm mx-1 btn-outline-secondary"
                        @click="toggleEdit(process)"
                      >
                        <i class="fas fa-fw fa-1x fa-pen" />
                      </button>
                      <button
                        class="btn btn-sm btn-outline-warning"
                        @click="entityToArchiveId = process.id; archiveModal.show();"
                      >
                        <i class="fas fa-archive fa-fw fa-1x mx-1" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <pagination
        v-show="totalCount > 0"
        :page="page"
        :total-count="totalCount"
        :items-per-page="itemsPerPage"
      />
    </template>

    <archive-process-modal
      ref="archiveModal"
      :entity-id="entityToArchiveId"
    />

    <new-process-modal
      ref="newModal"
      :sections="sections"
    />

    <edit-process-modal
      ref="editModal"
      :process="entityToEdit"
      :sections="sections"
    />
  </div>
</template>

<script>
import { ProcessesAPI, SectionsAPI } from '../../api/modules';
import { ProcessSearchForm } from '../forms/search';
import {
  LoadingAnimation,
  NoResults,
  FailedToLoad,
  Pagination
} from '../shared';
import { Modal } from '../../main';
import { ArchiveProcessModal } from '../shared/modals/archive';
import { NewProcessModal } from '../shared/modals/new';
import { EditProcessModal } from '../shared/modals/edit';
import { apiFetchItemsLimit } from '../../const';

export default {
  name: 'ProcessPage',
  components: {
    EditProcessModal,
    NewProcessModal,
    ArchiveProcessModal,
    ProcessSearchForm,
    Pagination,
    LoadingAnimation,
    NoResults,
    FailedToLoad
  },
  data() {
    return {
      processes: null,
      isLoading: {
        processes: true,
        sections: true
      },
      entityToEdit: null,
      showForm: false,
      sections: null,
      sectionId: null,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      searchForm: ProcessSearchForm,
      archiveModal: null,
      editModal: null,
      entityToArchiveId: null,
      newModal: null
    };
  },
  async created() {
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.archive', this.modalArchiveHandler);
    this.$emitter.on('process.add', this.processCreated);
    this.$emitter.on('process.edited', this.processEdited);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.search', this.formSearch);

    await this.loadProcesses();
    await this.loadSections();
  },
  mounted() {
    this.archiveModal = new Modal(this.$refs.archiveModal.$el, { focus: false });
    this.newModal = new Modal(this.$refs.newModal.$el, { focus: false });
    this.editModal = new Modal(this.$refs.editModal.$el, { focus: false });

    this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
      this.entityToEdit = null;

      this.$emitter.emit('modal.hidden', 'processPage');
    });
    this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'processPage');
    });
  },
  unmounted() {
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.archive', this.modalArchiveHandler);
    this.$emitter.off('process.add', this.processCreated);
    this.$emitter.off('process.edited', this.processEdited);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.search', this.formSearch);
  },
  methods: {
    async loadProcesses() {
      this.isLoading.processes = true;
      try {
        const response = await ProcessesAPI.getProcesses(this.page, this.itemsPerPage, this.query);

        this.processes = response.data;

        this.totalCount = response.summary.countAll;
      } catch {}

      this.isLoading.processes = false;
    },
    async loadSections() {
      try {
        const response = await SectionsAPI.getSections(1, apiFetchItemsLimit, {});
        this.sections = response.data;
      } catch {}

      this.isLoading.sections = false;
    },
    async updateProcess(process) {
      try {
        let processToUpdate = JSON.parse(JSON.stringify(process));

        processToUpdate.section = this.sectionId;

        const response = await ProcessesAPI.updateProcess(processToUpdate);

        if (response) {
          let index = this.processes.findIndex(process => process.id === response.id);

          if (index !== -1) {
            this.processes[index] = response;
          }
        }
      } catch {}

      this.entityToEdit = null;
    },
    async deleteProcess(id) {
      await this.$alert.success('Process archived');

      const index = this.processes.findIndex(process => process.id === id);

      if (index !== -1) {
        this.processes.splice(index, 1);
      }
    },
    processEdited(process) {
      const index = this.processes.findIndex(v => v.id === process.id);

      if (index !== -1) {
        this.$set(this.processes, index, process);
      }

      this.editModal.hide();
    },
    toggleEdit(process) {
      this.entityToEdit = process;
      this.sectionId = process.section.id;

      this.editModal.show();
    },
    processCreated() {
      this.newModal.hide();
      this.loadProcesses();
    },
    async pageChanged(page) {
      this.page = page;
      await this.loadProcesses();
    },
    async formSearch(values) {
      this.query = values;
      await this.loadProcesses();
    },
    modalHideHandler(event) {
      if (event.from === 'archiveProcessModal') {
        this.archiveModal.hide();
      }
    },
    modalArchiveHandler(event) {
      if (event.from === 'archiveProcessModal') {
        this.deleteProcess(event.id);

        if (this.page > 1 && this.processes.length === 1) {
          this.page--;
        }

        this.loadProcesses();
      }
    },
    async exportProcesses() {
      const response = await ProcessesAPI.exportProcesses(this.query);

      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        link.download = 'Processes.xls';
        link.click();
      }
    }
  }
};

</script>

<style scoped>

</style>
