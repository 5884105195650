<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Section Name <span class="text-danger">*</span>
          </label>
          <input
            v-model="section.name"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Section Name"
          >
        </div>
      </div>
      <div class="col-12">
        <button
          class="btn btn-primary-green me-2"
          type="submit"
          :disabled="disabled"
        >
          <span v-html="buttonText" />
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { SectionsAPI } from '../../api/modules';

export default {
  name: 'SectionForm',
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      section: {
        name: ''
      }
    };
  },
  computed: {
    buttonText() {
      let buttonText;

      if (this.isSubmitting) {
        buttonText = this.entity ? 'Saving <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Adding <i class="fas fa-spin fa-spinner ms-2"></i>';
      } else {
        buttonText = this.entity ? 'Save <i class="fas fa-save ms-2"></i>' : 'Add <i class="fas fa-plus ms-2"></i>';
      }

      return buttonText;
    },
    disabled() {
      return this.section.name === '';
    }
  },
  watch: {
    entity() {
      this.section = JSON.parse(JSON.stringify(this.entity));
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      const apiMethod = this.entity
        ? () => SectionsAPI.updateSection(this.section)
        : () => SectionsAPI.createSection(this.section);
      const response = await apiMethod();

      if (response) {
        this.$emitter.emit(this.entity ? 'section.edited' : 'section.add', response);

        await this.$alert.success(this.entity ? 'Section edited' : 'Section added');
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.section.name = null;
    },
    modalHiddenHandler(source) {
      if (source === 'sectionPage') {
        this.resetForm();
      }
    }
  }
};
</script>
