<template>
  <div
    class="row mb-3"
    :class="wrapperClass"
  >
    <template v-if="placement === 'top'">
      <div class="col">
        <label
          v-if="label"
          :for="name"
          :class="labelClass"
        >
          {{ label }}

          <span
            v-if="required"
            class="text-danger"
          >*</span>
        </label>

        <slot />
      </div>
    </template>

    <template v-else-if="placement === 'left'">
      <div class="col">
        <label
          v-if="label"
          :for="name"
          :class="labelClass"
        >
          {{ label }}

          <span
            v-if="required"
            class="text-danger"
          >*</span>
        </label>
      </div>

      <div class="col">
        <slot />
      </div>
    </template>

    <template v-else-if="placement === 'right'">
      <div class="col">
        <slot />
      </div>

      <div class="col">
        <label
          v-if="label"
          :for="name"
          :class="labelClass"
        >
          {{ label }}

          <span
            v-if="required"
            class="text-danger"
          >*</span>
        </label>
      </div>
    </template>

    <template v-if="error">
      <span class="text-danger">{{ error }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InputWrapper',
  props: {
    label: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: 'form-label'
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      defualt: true
    },
    placement: {
      type: String,
      default: 'top',
      validator: value => {
        return ['top', 'left', 'right'].includes(value);
      }
    },
    wrapperClass: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  }
};
</script>
