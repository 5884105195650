import qs from 'qs';

import API from '../api';

export default {
  getUsers(page, itemsPerPage, query) {
    let url = `/users?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => response.data);
  },
  createUser(user) {
    const data = qs.stringify(user);

    return API.post('/users/', data)
      .then(response => response.data);
  },
  async updateUser(user) {
    const data = qs.stringify(user);
    return API.put(`/users/${user.id}`, data);
  },
  deleteUser(id) {
    return API.delete(`/users/${id}`)
      .then(response => response.data);
  },
  exportUsers(query) {
    let url = '/users/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};

