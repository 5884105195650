<template>
  <form @submit.prevent="onSubmit">
    <p class="mb-4">
      Generate a report of all users' time sheets
    </p>

    <div class="row mt-3">
      <div class="col-auto">
        <div class="mb-3">
          <!--          <span-->
          <!--            class="fs-7 me-1 px-2 py-1 d-inline-block"-->
          <!--            style="background: #6a6a6a; border-radius: 3px 3px 0 0;color: #fff;"-->
          <!--          >From</span>-->
          <label class="form-label">
            Start date <span class="text-danger">*</span>
          </label>
          <div class="input-group rounded">
            <span class="input-group-text">
              <span class="fs-7 me-2 py-1 d-inline-block">From</span>
              <i class="fas fa-calendar" />
            </span>
            <button
              ref="startDatePicker"
              :class="{ focus: showStartDatePicker }"
              class="btn btn-primary-white fs-5 select-date-btn"
              type="button"
              @click="showStartDatePickerMethod"
            >
              <template v-if="report.startDate">
                <span class="fs-6">{{ formatDate(report.startDate) }}</span>
              </template>

              <template v-else>
                <span class="fs-6 opacity-50">Select start date</span>
              </template>
            </button>

            <input
              v-show="showStartDatePicker"
              ref="startDate"
              class="form-control date-picker-input"
              style="right: 0; left: unset !important; position: absolute !important; width: 30px;"
              type="text"
            >
          </div>
        </div>
      </div>

      <div class="col-auto">
        <div class="mb-3">
          <!--          <span-->
          <!--            class="fs-7 me-1 px-2 py-1 d-inline-block"-->
          <!--            style="background: #6a6a6a; border-radius: 3px 3px 0 0;color: #fff;"-->
          <!--          >To</span>-->
          <label class="form-label">
            End date <span class="text-danger">*</span>
          </label>
          <div class="input-group rounded">
            <span class="input-group-text">
              <span class="fs-7 me-2 py-1 d-inline-block">To</span>
              <i class="fas fa-calendar" />
            </span>
            <button
              ref="endDatePicker"
              :class="{ focus: showEndDatePicker }"
              class="btn btn-primary-white fs-5 select-date-btn"
              type="button"
              @click="showEndDatePickerMethod"
            >
              <template v-if="report.endDate">
                <span class="fs-6">{{ formatDate(report.endDate) }}</span>
              </template>

              <template v-else>
                <span class="fs-6 opacity-50">Select end date</span>
              </template>
            </button>
          </div>
          <input
            v-show="showEndDatePicker"
            ref="endDate"
            class="form-control date-picker-input position-absolute"
            style="right: 0; left: unset !important; position: absolute !important; width: 30px;"
            type="text"
          >
        </div>
      </div>
    </div>

    <div class="col-3">
      <button
        class="btn btn-primary-green mt-2"
        type="submit"
        :disabled="disabled"
      >
        <span v-html="buttonText" />
      </button>
    </div>
  </form>
</template>

<script>
import datepicker from 'js-datepicker';

import { ReportsAPI } from '../../api/modules';
import { DateTime } from 'luxon';

export default {
  name: 'ReportForm',
  data() {
    return {
      isSubmitting: false,
      report: {
        startDate: null,
        endDate: null
      },
      showStartDatePicker: false,
      showEndDatePicker: false
    };
  },
  computed: {
    buttonText() {
      return this.isSubmitting ? 'Generating <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Generate';
    },
    disabled() {
      const { startDate, endDate } = this.report;

      return !startDate || !endDate;
    }
  },
  created() {
    this.$emitter.on('form.reset', this.formResetHandler);
  },
  unmounted() {
    this.$emitter.off('form.reset', this.formResetHandler);
  },
  mounted() {
    if ('startDate' in this.$refs && 'endDate' in this.$refs) {
      datepicker(this.$refs.startDate, {
        onSelect: (instance, date) => {
          this.report.startDate = new Date(date);
          this.showStartDatePicker = !this.showStartDatePicker;
        }
      });

      datepicker(this.$refs.endDate, {
        onSelect: (instance, date) => {
          this.report.endDate = new Date(date);
          this.showEndDatePicker = !this.showEndDatePicker;
        }
      });
    }

    document.addEventListener('click', e => {
      const { startDate, endDate } = this.$refs;

      if (this.showStartDatePicker && !startDate.contains(e.target)) {
        setTimeout(() => {
          this.showStartDatePicker = false;
        }, 1);
      }

      if (this.showEndDatePicker && !endDate.contains(e.target)) {
        setTimeout(() => {
          this.showEndDatePicker = false;
        }, 1);
      }
    });
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.report.startDate.setHours(0);
      this.report.startDate.setMinutes(0);
      this.report.startDate.setSeconds(0);

      this.report.endDate.setHours(23);
      this.report.endDate.setMinutes(59);
      this.report.endDate.setSeconds(59);

      const response = await ReportsAPI.generateReport(this.report);

      if (response) {
        const link = document.createElement('a');

        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        // link.target = '_blank';
        link.download = 'report.xls';

        link.click();
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    showStartDatePickerMethod() {
      setTimeout(() => {
        setTimeout(() => {
          this.$refs.startDate.focus();
        }, 1);

        this.showStartDatePicker = !this.showStartDatePicker;
      }, 1);
    },
    showEndDatePickerMethod() {
      setTimeout(() => {
        setTimeout(() => {
          this.$refs.endDate.focus();
        }, 1);

        this.showEndDatePicker = !this.showEndDatePicker;
      }, 1);
    },
    formatDate(date) {
      const luxonDate = DateTime.fromJSDate(date);
      const format = { month: 'short', weekday: 'short', day: 'numeric' };

      return luxonDate.setZone('Africa/Johannesburg').toLocaleString(format);
    },
    resetForm() {
      this.report.startDate = null;
      this.report.endDate = null;
    },
    formResetHandler(source) {
      if (source === 'reportForm') {
        this.resetForm();
      }
    }
  }
};
</script>
