<template>
  <div class="row">
    <div class="col-12 py-5">
      <div class="text-center">
        <p>
          {{ text }}
        </p>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResults',
  props: {
    text: {
      type: String,
      default: 'No Results...'
    }
  }
};
</script>

<style scoped>

</style>