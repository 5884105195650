// const AUTHENTICATING = 'AUTHENTICATING';
// const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
// const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
// const LOGGING_OUT = 'LOGGING_OUT';
// const LOGGED_OUT = 'LOGGED_OUT';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    user: null
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    hasAdminRole(state) {
      return state.user ? state.user.role === 'ROLE_ADMIN' : false;
    }
  },
  mutations: {
    // [AUTHENTICATING] (state) {
    //   state.isLoading = true;
    //   state.error = null;
    //   state.isAuthenticated = false;
    //   state.user = null;
    // },
    // [AUTHENTICATION_SUCCESS] (state, user) {
    //   state.isLoading = false;
    //   state.error = null;
    //   state.isAuthenticated = true;
    //   state.user = user;
    // },
    // [AUTHENTICATION_ERROR] (state, error) {
    //   state.isLoading = false;
    //   state.error = error;
    //   state.isAuthenticated = false;
    //   state.user = null;
    // },
    // [LOGGING_OUT] (state) {
    //   state.isLoading = true;
    //   state.error = null;
    // },
    // [LOGGED_OUT] (state) {
    //   state.isLoading = false;
    //   state.error = null;
    //   state.isAuthenticated = false;
    //   state.user = null;
    // },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    }
  },
  actions: {
    async setIsAuthenticated({ commit }, payload) {
      await commit('setIsAuthenticated', payload);
    }
  }
};
