import API from '../api';

export default {
  login(username, password) {
    return API.post('/auth/login', {
      username,
      password
    }, { headers: { 'Content-Type': 'application/json' } }).then(response => response.data);
  },

  logout() {
    return API.get('/auth/logout')
      .then(() => true);
  },

  changePassword(oldPassword, password) {
    return API.post('/auth/change-password', {
      oldPassword,
      password
    }).then(response => response.data);
  }
};
