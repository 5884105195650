<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            New Process
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <process-form
            :sections="sections"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessForm from '../../../forms/ProcessForm';

export default {
  name: 'NewProcessModal',
  components: { ProcessForm },
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  }
};
</script>
