<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            New Time Entry
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div class="modal-body">
          <line-item-form
            :machines="machines"
            :processes="processes"
            :sections="sections"
            :parts="parts"
            :batch-numbers="batchNumbers"
            :current-date="currentDate"
            :operator-id="operatorId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineItemForm from '../../../forms/LineItemForm';

export default {
  name: 'NewLineItemModal',
  components: { LineItemForm },
  props: {
    machines: {
      type: Array,
      default: () => []
    },
    sections: {
      type: Array,
      default: () => []
    },
    processes: {
      type: Array,
      default: () => []
    },
    parts: {
      type: Array,
      default: () => []
    },
    batchNumbers: {
      type: Array,
      default: () => []
    },
    currentDate: {
      type: Date,
      default: () => null
    },
    operatorId: {
      type: String,
      default: null
    }
  }
};
</script>
