<template>
  <div>
    <template v-if="isLoading">
      <loading-animation text="Loading Meshgear..." />
    </template>

    <template v-else-if="!profile || error">
      <section class="vh-100 d-flex justify-content-center align-items-center">
        <div class="container text-center">
          <img
            style="height: 50px; width: auto; margin: auto"
            alt="Mesh Gear logo"
            src="../../assets/images/logo.png"
            class="d-block pr-3 mb-5 d-block"
          >
          <div
            class="card mx-auto w-50"
            style="min-width: 300px"
          >
            <div class="card-body">
              <i class="fas fa-cogs fa-3x mb-3 mt-4 mx-auto d-block text-center text-danger" />
              <h3 class="text-center">
                {{ error }}
              </h3>
              <router-link
                :to="{ name: 'app_login' }"
                class="btn btn-green mt-4"
              >
                <i class="far fa-arrow-left" /> Return to app login
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template v-else>
      <Navbar />

      <div
        class="container container-sm bg-light"
        style="height: 100vh;"
      >
        <router-view />
      </div>

      <template v-if="$store.getters['alert/getType']">
        <div
          class="d-flex w-100 position-fixed text-center justify-content-center"
          style="bottom: 20px"
        >
          <div
            class="alert shadow"
            :class="`alert-${$store.getters['alert/getType']}`"
          >
            {{ $store.getters['alert/getMessage'] }}

            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              @click="hideAlert"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ProfileAPI } from '../../api/modules';
import { LoadingAnimation } from '../shared';
import Navbar from '../shared/Navbar';

export default {
  name: 'Page',
  components: { Navbar, LoadingAnimation },
  data() {
    return {
      isLoading: true,
      error: null,
      visible: false,
      offCanvas: null
    };
  },
  computed: {
    profile() {
      return this.$store.getters['profile/getProfile'];
    },
    isAdmin() {
      return this.profile && this.profile.roles === 'Admin';
    },
    requiresAdmin() {
      return 'requiresAdmin' in this.$route.meta && this.$route.meta.requiresAdmin;
    }
  },
  async created() {
    if (this.$route.name !== 'app_login') {
      await this.loadProfile()
        .then(async () => {
          if (this.profile) {
            await this.loadSite();
          } else {
            await this.$router.push({ name: 'app_login' });

            this.error = 'An unknown error occurred';
          }
        })
        .catch(() => {
          this.error = 'Failed to load Meshgear';
        }).finally(() => {
          this.isLoading = false;
        });
    }

    if (this.requiresAdmin && !this.isAdmin) {
      await this.$router.push({ name: 'app_line_items' });
    }
  },
  methods: {
    async hideAlert(){
      await this.$alert.hide();
    },
    async loadSite() {
      const disallowedRouteNames = [
        'app_machines',
        'app_processes',
        'app_sections',
        'app_users'
      ];

      if (disallowedRouteNames.includes(this.$route.name) && this.profile.roles.includes('ROLE_USER')) {
        await this.$router.push({ name: 'app_line_items' });

        return;
      }

      if (this.$route.path === '/') {
        await this.$router.push({ name: 'app_line_items' });
      }

      // If the current route is not already the login page, redirect to the login page (otherwise don't redirect,
      // since we're already on the login page
      if (this.$route.name !== 'app_login' && !this.$store.getters['security/isAuthenticated']) {
        return this.$router.push({ name: 'app_login' });
      }
    },
    async loadProfile() {
      try {
        const response = await ProfileAPI.getProfile();

        await this.$store.dispatch('security/setIsAuthenticated', true);
        await this.$store.dispatch('profile/setProfile', response);
      } catch {}
    }
  }
};
</script>
