export default {
  state: {
    type: null,
    message: null
  },
  namespaced: true,
  getters: {
    getType: state => state.type,
    getMessage: state => state.message
  },
  mutations: {
    setType(state, payload) {
      state.type = payload;

    },
    setMessage(state, payload) {
      state.message = payload;
    }
  },
  actions: {
    async setType({ commit }, payload) {
      commit('setType', payload);

      setTimeout(() => {
        commit('setType', null);
        commit('setMessage', null);
      }, 4000);
    },
    async setMessage({ commit }, payload) {
      commit('setMessage', payload);
    }
  }
};
