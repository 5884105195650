import API from '../api';
import qs from 'qs';

export default {
  getBatchNumbers(page, itemsPerPage, query) {
    let url = `/batch-numbers?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createBatchNumber(batchNumber) {
    const data = qs.stringify(batchNumber);

    return API.post('/batch-numbers/', data)
      .then(response => {
        return response.data;
      });
  },
  updateBatchNumber(batchNumber) {
    const data = qs.stringify(batchNumber);

    return API.put(`/batch-numbers/${batchNumber.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteBatchNumber(id) {
    return API.delete(`/batch-numbers/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportBatchNumbers(query) {
    let url = '/batch-numbers/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};
