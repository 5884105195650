import API from '../api';
import qs from 'qs';

export default {
  getProcesses(page, itemsPerPage, query) {
    let url = `/processes?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createProcess(process) {
    const data = qs.stringify(process);

    return API.post('/processes/', data)
      .then(response => {
        return response.data;
      });
  },
  updateProcess(process) {
    const data = qs.stringify(process);

    return API.put(`/processes/${process.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteProcess(id) {
    return API.delete(`/processes/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportProcesses(query) {
    let url = '/processes/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};

