<template>
  <div class="card line-item mb-3">
    <div class="card-body">
      <div class="row line-item-card">
        <div class="col-auto">
          <div class="d-flex align-items-lg-center align-items-start">
            <div class="d-block d-sm-inline-block d-lg-block me-4 me-lg-0">
              <p class="line-item-label">
                Start Time
              </p>
              <p class="fw-bold mb-1 time-entry">
                {{ formatTime(lineItem.startTime) }}
              </p>
            </div>

            <div class="d-sm-inline-block d-lg-block">
              <p class="line-item-label">
                End Time
              </p>
              <p class="text-secondary mb-1 time-entry">
                {{ formatTime(lineItem.endTime) }}
              </p>
            </div>
          </div>

          <div>
            <div class="d-inline-block d-lg-block me-2">
              <p class="line-item-label text-center">
                Mins Lost
              </p>
              <ul class="list-group">
                <li class="list-group-item mg-list-group-item text-center">
                  {{ lineItem.minutesLost }}
                </li>
              </ul>
            </div>

            <div
              v-if="lineItem.workedLunch"
              class="d-inline-block d-lg-block"
            >
              <div
                ref="lunchTooltip"
                class="lunch-icon-container opacity-50 mt-2 d-flex justify-content-center align-items-center"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Worked during lunch break"
              >
                <i class="fas fa-utensils text-secondary fa-fw lunch-icon" />
              </div>
            </div>
          </div>
        </div>

        <!-- line item qty col -->
        <div class="col-auto">
          <div class="d-flex align-items-center mb-2">
            <div class="pe-2">
              <p class="line-item-label text-center">
                Qty Good
              </p>
              <div class="line-item-qty-container">
                <p class="line-item-qty">
                  {{ lineItem.qtyGood }}
                </p>
              </div>
            </div>
            <div>
              <p class="line-item-label text-center">
                Qty Scrap
              </p>
              <div class="line-item-qty-container">
                <p class="line-item-qty">
                  {{ lineItem.qtyScrap }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 my-2 d-lg-none" />

        <div class="col-12 d-block d-lg-none">
          <div class="row line-item-info">
            <div class="col-auto px-0 info-col-1">
              <div class="px-2">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item mg-list-group-item">
                    <span class="list-group-item-label">Part #:</span>
                    <span class="list-group-item-content">{{ lineItem.part.partNumber }} - {{ lineItem.part.name }}</span>
                  </li>
                  <li class="list-group-item mg-list-group-item mg-last-item">
                    <span class="list-group-item-label">Section:</span>
                    <span class="list-group-item-content">{{ lineItem.section.name }}</span>
                  </li>
                  <li class="list-group-item mg-list-group-item">
                    <span class="list-group-item-label">Batch #:</span>
                    <span class="list-group-item-content">{{ lineItem.batchNumber.name }} - {{ lineItem.batchNumber.year }}</span>
                  </li>
                  <li class="list-group-item mg-list-group-item">
                    <span class="list-group-item-label">Process:</span>
                    <span class="list-group-item-content">{{ lineItem.process.name }}</span>
                  </li>
                  <li class="list-group-item mg-list-group-item">
                    <span class="list-group-item-label">Machine:</span>
                    <template v-if="lineItem.machine">
                      <span class="list-group-item-content">{{ lineItem.machine.serialNumber }} - {{ lineItem.machine.name }}</span>
                    </template>
                    <template v-else>
                      <span class="list-group-item-content">--</span>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <template v-if="lineItem.comments">
          <div class="col-12 py-3 d-block d-lg-none">
            <div>
              <p class="line-item-comment">
                {{ lineItem.comments }}
              </p>
            </div>
          </div>
        </template>

        <div class="col-12">
          <div class="d-flex justify-content-start border-top pt-3">
            <button
              class="btn btn-sm btn-outline-secondary me-2"
              @click="handleEdit"
            >
              <span v-show="isEditing">
                <i class="fas fa-fw fa-1x fa-check" />
              </span>
              <span v-show="!isEditing">
                <i class="fas fa-fw fa-1x fa-pen" />
              </span>
            </button>

            <button
              class="btn btn-sm btn-outline-danger"
              @click="handleDelete"
            >
              <i class="fas fa-trash fa-fw fa-1x" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { LineItemMixin } from '../../shared/mixins';

export default {
  name: 'LineItemMobileCard',
  mixins: [
    LineItemMixin
  ]
};
</script>
