import qs from 'qs';

import API from '../api';

export default {
  getLineItems(page, itemsPerPage, query) {
    let url = `/line-items?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query);

      url += `&${queryString}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  },
  createLineItem(lineItem) {
    const data = qs.stringify(lineItem);

    return API.post('/line-items', data)
      .then(response => {
        return response.data;
      });
  },
  updateLineItem(lineItem) {
    const data = qs.stringify(lineItem);

    return API.put(`/line-items/${lineItem.id}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteLineItem(id) {
    return API.delete(`/line-items/${id}`)
      .then(response => {
        return response.data;
      });
  },
  exportLineItems(query) {
    let url = '/line-items/export';

    if (query && Object.keys(query).length) {
      url += `?${qs.stringify(query)}`;
    }

    return API.get(url)
      .then(response => {
        return response.data;
      });
  }
};

