export default {
  state: {
    profile: null
  },
  namespaced: true,
  getters: {
    getProfile: state => state.profile
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    }
  },
  actions: {
    async setProfile({ commit }, payload) {
      commit('setProfile', payload);
    }
  }
};
