<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto">
        <h1 class="d-inline-block">
          Batch Numbers
        </h1>
      </div>

      <div class="col-sm-auto ms-auto col-12 text-center">
        <button
          class="btn btn-primary-green w-100 mx-md-0 mx-auto add-btn"
          style="min-width: 200px; max-width: 200px"
          @click="newModal.show()"
        >
          <i class="fas fa-fw fa-1x fa-plus" />
          &nbsp;New Batch Number
        </button>
        <button
          class="btn btn-outline-secondary ms-1"
          type="submit"
          @click="exportBatchNumbers"
        >
          <i class="far fa-download" />
          &nbsp;Export
        </button>
      </div>
    </div>

    <batch-number-search-form :query="query" />

    <template v-if="isLoading">
      <loading-animation text="Loading Batch Numbers..." />
    </template>

    <div v-else-if="!batchNumbers">
      <failed-to-load text="Failed to load batch numbers" />
    </div>

    <template v-else-if="!batchNumbers.length">
      <no-results text="There are no batch numbers" />
    </template>

    <template v-else>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table
              class="table table-hover mg-table"
              style="min-width: 250px"
            >
              <thead>
                <tr>
                  <th scope="col">
                    Name
                  </th>

                  <th scope="col">
                    Year
                  </th>

                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                <template v-for="batchNumber in batchNumbers">
                  <tr :key="batchNumber.id">
                    <td>
                      {{ batchNumber.name }}
                    </td>
                    <td>{{ batchNumber.year }}</td>

                    <td class="text-end">
                      <button
                        class="btn btn-sm btn-outline-secondary mx-1"
                        @click="toggleEdit(batchNumber)"
                      >
                        <i class="fas fa-fw fa-1x fa-pen" />
                      </button>

                      <button
                        class="btn btn-sm btn-outline-warning"
                        @click="entityToArchiveId = batchNumber.id; archiveModal.show()"
                      >
                        <i class="fas fa-archive fa-fw fa-1x mx-1" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <pagination
          v-show="totalCount > 0"
          :page="page"
          :total-count="totalCount"
          :items-per-page="itemsPerPage"
        />
      </div>
    </template>

    <archive-batch-number-modal
      ref="archiveModal"
      :entity-id="entityToArchiveId"
    />

    <new-batch-number-modal ref="newModal" />

    <edit-batch-number-modal
      ref="editModal"
      :batch-number="entityToEdit"
    />
  </div>
</template>

<script>
import { BatchNumbersAPI } from '../../api/modules';
import { BatchNumberSearchForm } from '../forms/search';
import {
  Pagination,
  LoadingAnimation,
  FailedToLoad
} from '../shared';
import { Modal } from '../../main';
import { ArchiveBatchNumberModal } from '../shared/modals/archive';
import { NewBatchNumberModal } from '../shared/modals/new';
import { NoResults } from '../shared';
import EditBatchNumberModal from '../shared/modals/edit/EditBatchNumberModal';

export default {
  name: 'BatchNumberPage',
  components: {
    EditBatchNumberModal,
    NoResults,
    BatchNumberSearchForm,
    FailedToLoad,
    Pagination,
    LoadingAnimation,
    ArchiveBatchNumberModal,
    NewBatchNumberModal
  },
  data() {
    return {
      batchNumbers: null,
      isLoading: true,
      entityToEdit: null,
      page: 1,
      itemsPerPage: 20,
      totalCount: 0,
      query: {},
      searchForm: BatchNumberSearchForm,
      archiveModal: null,
      newModal: null,
      editModal: null,
      entityToArchiveId: null
    };
  },
  async created() {
    this.$emitter.on('modal.hide', this.modalHideHandler);
    this.$emitter.on('modal.archive', this.modalArchiveHandler);
    this.$emitter.on('batchNumber.add', this.batchNumberCreated);
    this.$emitter.on('batchNumber.edited', this.batchNumberEdited);
    this.$emitter.on('pagination.pageChanged', this.pageChanged);
    this.$emitter.on('form.search', this.formSearch);

    await this.loadBatchNumbers();

    this.isLoading = false;
  },
  mounted() {
    this.archiveModal = new Modal(this.$refs.archiveModal.$el, { focus: false });
    this.newModal = new Modal(this.$refs.newModal.$el, { focus: false });
    this.editModal = new Modal(this.$refs.editModal.$el, { focus: false });

    this.$refs.editModal.$el.addEventListener('hidden.bs.modal', () => {
      this.entityToEdit = null;

      this.$emitter.emit('modal.hidden', 'batchNumberPage');
    });
    this.$refs.newModal.$el.addEventListener('hidden.bs.modal', () => {
      this.$emitter.emit('modal.hidden', 'batchNumberPage');
    });
  },
  unmounted() {
    this.$emitter.off('modal.hide', this.modalHideHandler);
    this.$emitter.off('modal.archive', this.modalArchiveHandler);
    this.$emitter.off('batchNumber.add', this.batchNumberCreated);
    this.$emitter.off('batchNumber.edited', this.batchNumberEdited);
    this.$emitter.off('pagination.pageChanged', this.pageChanged);
    this.$emitter.off('form.search', this.formSearch);
  },
  methods: {
    async loadBatchNumbers() {
      this.isLoading = true;

      try {
        const response = await BatchNumbersAPI.getBatchNumbers(this.page, this.itemsPerPage, this.query);

        this.batchNumbers = response.data;
        this.totalCount = response.summary.countAll;
      } catch {}

      this.isLoading = false;
    },
    async deleteBatchNumber(id) {
      try {
        const response = await BatchNumbersAPI.deleteBatchNumber(id);

        if (response) {
          await this.$alert.success('Batch number archived');
        }

        const index = this.batchNumbers.findIndex(batchNumber => batchNumber.id === id);

        this.batchNumbers.splice(index, 1);

      } catch {}
    },
    toggleEdit(batchNumber) {
      this.entityToEdit = batchNumber;

      this.editModal.show();
    },
    batchNumberCreated() {
      this.newModal.hide();
      this.loadBatchNumbers();
    },
    batchNumberEdited(batchNumber) {
      const index = this.batchNumbers.findIndex(v => v.id === batchNumber.id);

      if (index !== -1) {
        this.$set(this.batchNumbers, index, batchNumber);
      }

      this.editModal.hide();
    },
    async pageChanged(page) {
      this.page = page;

      await this.loadBatchNumbers();
    },
    async formSearch(values) {
      this.query = values;

      await this.loadBatchNumbers();
    },
    modalHideHandler(event) {
      if (event.from === 'archiveBatchNumberModal') {
        this.archiveModal.hide();
      }
    },
    async modalArchiveHandler(event) {
      if (event.from === 'archiveBatchNumberModal') {
        await this.deleteBatchNumber(event.id);

        if (this.page > 1 && this.batchNumbers.length === 1) {
          this.page--;
        }

        await this.loadBatchNumbers();
      }
    },
    async exportBatchNumbers() {
      const response = await BatchNumbersAPI.exportBatchNumbers(this.query);
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.ms-excel;base64,${response}`;
        link.download = 'Batch Numbers.xls';
        link.click();
      }
    }
  }
};
</script>
