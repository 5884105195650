<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row bg-white">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Machine Name <span class="text-danger">*</span>
          </label>

          <input
            v-model="machine.name"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Machine name"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Machine serial number <span class="text-danger">*</span>
          </label>
          <input
            v-model="machine.serialNumber"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Serial number"
          >
        </div>

        <div class="mb-3">
          <label class="form-label">
            Machine section <span class="text-danger">*</span>
          </label>
          <multi-select-input
            :options="sectionOptions"
            name="section"
            placeholder="Select a section"
            no-results-text="No sections found"
            class="w-100"
          />
        </div>
      </div>

      <div class="col-12">
        <button
          class="btn btn-primary-green me-2"
          type="submit"
          :disabled="disabled"
        >
          <span v-html="buttonText" />
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { MachinesAPI } from '../../api/modules';
import { MultiSelectInput } from '../shared/forms/controls';

export default {
  name: 'MachineForm',
  components: { MultiSelectInput },
  props: {
    sections: {
      type: Array,
      default: () => []
    },
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      machine: {
        name: '',
        serialNumber: '',
        section: ''
      }
    };
  },
  computed: {
    buttonText() {
      let buttonText;

      if (this.isSubmitting) {
        buttonText = this.entity ? '<i class="fas fa-spin fa-spinner ms-2"></i>&nbsp; Saving' : '<i class="fas fa-spin fa-spinner ms-2"></i>&nbsp; Adding';
      } else {
        buttonText = this.entity ? '<i class="fas fa-save ms-2"></i>&nbsp; Save' : '<i class="fas fa-plus ms-2"></i> Add';
      }

      return buttonText;
    },
    disabled() {
      const { name, section, serialNumber } = this.machine;

      return name === '' || !section || serialNumber === '';
    },
    sectionOptions() {
      if (!this.sections) {
        return [];
      }

      return this.sections.map(section => {
        return {
          value: section.id,
          label: section.name
        };
      });
    }
  },
  watch: {
    entity() {
      this.machine = JSON.parse(JSON.stringify(this.entity));
      this.machine.section = this.entity.section.id;

      this.$emitter.emit('form.setFieldValue', {
        field: 'section',
        value: this.entity.section.id
      });
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
    this.$emitter.on('form.select.changed', this.selectChangedHandler);
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
    this.$emitter.off('form.select.changed', this.selectChangedHandler);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      const apiMethod = this.entity
        ? () => MachinesAPI.updateMachine(this.machine)
        : () => MachinesAPI.createMachine(this.machine);
      const response = await apiMethod();

      if (response) {
        this.$emitter.emit(this.entity ? 'machine.edited' : 'machine.add', response);

        await this.$alert.success(this.entity ? 'Machine edited' : 'Machine added');
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.machine.name = '';
      this.machine.serialNumber = '';
      this.machine.section = 'default';
      this.sectionId = 'default';

      this.$emitter.emit('form.setFieldValue', {
        field: 'section',
        value: null
      });

      this.$emitter.emit('form.setFieldValue', {
        field: 'section',
        value: null
      });
    },
    modalHiddenHandler(source) {
      if (source === 'machinePage') {
        this.resetForm();
      }
    },
    selectChangedHandler(event) {
      console.log(event);

      if (event.field === 'section') {
        this.sectionId = event.value;
        this.machine.section = event.value;
      }
    }
  }
};
</script>
