<template>
  <div>
    <div class="row align-items-center py-3">
      <div class="col-auto mb-3">
        <h2 class="d-inline-block">
          Reports
        </h2>
      </div>

      <report-form />
    </div>
  </div>
</template>

<script>
import ReportForm from '../forms/ReportForm';

export default {
  name: 'MachinePage',
  components: { ReportForm }
};
</script>
