<template>
  <div>
    <input-wrapper
      :label="label"
      :name="name"
      :required="required"
      placement="left"
      label-class="form-check-label"
      wrapper-class="mesh-gear-checkbox mt-3 form-check"
    >
      <input
        v-model="value"
        type="checkbox"
        :name="name"
        class="mesh-gear-checkbox form-check"
        :placeholder="placeholder"
        @change="handleUserInput"
      >
    </input-wrapper>
  </div>
</template>

<script>
import { FormControlMixin } from '../../mixins';
import { InputWrapper } from '../components';

export default {
  name: 'CheckboxInput',
  components: { InputWrapper },
  mixins: [FormControlMixin],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    cssClass: {
      type: String,
      default: 'form-check'
    }
  }
};
</script>
