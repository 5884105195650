<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row bg-white">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            First Name <span class="text-danger">*</span>
          </label>
          <input
            v-model="user.firstName"
            class="form-control"
            required
            type="text"
            name="firstName"
            placeholder="First Name"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Last Name <span class="text-danger">*</span>
          </label>
          <input
            v-model="user.lastName"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Last name"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Email <span class="text-danger">*</span>
          </label>
          <input
            v-model="user.username"
            class="form-control"
            required
            type="email"
            name="username"
            placeholder="Email"
            value="john.thomas@meshgear.co.za"
          >
        </div>
      </div>

      <template v-if="!entity">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Password <span class="text-danger">*</span>
            </label>
            <input
              v-model="user.plainPassword"
              class="form-control"
              required
              type="password"
              name="plainPassword"
              placeholder="Password"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Confirm password <span class="text-danger">*</span>
            </label>
            <input
              v-model="user.confirmPassword"
              class="form-control"
              required
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
            >
          </div>
        </div>
      </template>
    </div>

    <div class="col-12">
      <div class="mb-3">
        <div class="mesh-gear-checkbox mt-3 form-check">
          <input
            id="active"
            v-model="user.active"
            type="checkbox"
            :checked="user.active"
          >
          <label
            class="form-check-label"
            for="active"
          >
            Active <span class="text-danger">*</span>
          </label>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="mb-3">
        <label class="form-label">
          User Role
        </label>

        <multi-select-input
          :options="roleOptions"
          name="role"
          placeholder="Select a role"
          no-options-text="No roles found"
          no-results-text="No roles found"
          class="w-100"
        />
      </div>
    </div>

    <div class="col-12">
      <button
        class="btn btn-primary-green me-2"
        type="submit"
        :disabled="disabled"
      >
        <span v-html="buttonText" />
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { UsersAPI } from '../../api/modules';
import MultiSelectInput from '../shared/forms/controls/MultiSelectInput';

export default {
  name: 'UserForm',
  components: { MultiSelectInput },
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      user: {
        firstName: '',
        lastName: '',
        username: '',
        active: true,
        roles: 'default'
      }
    };
  },
  computed: {
    buttonText() {
      let buttonText;

      if (this.isSubmitting) {
        buttonText = this.entity ? 'Saving <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Adding <i class="fas fa-spin fa-spinner ms-2"></i>';
      } else {
        buttonText = this.entity ? 'Save <i class="fas fa-save ms-2"></i>' : 'Add <i class="fas fa-plus ms-2"></i>';
      }

      return buttonText;
    },
    disabled() {
      const { firstName, lastName, username, plainPassword, confirmPassword, roles } = this.user;

      if ('plainPassword' in this.user) {
        return firstName === '' || lastName === '' || username === '' || (confirmPassword !== plainPassword) || plainPassword === '' || roles === 'default';
      }

      return firstName === '' || lastName === '' || username === '' || roles === 'default';
    },
    roleOptions() {
      return ['Operator', 'Admin'].map(v => ({ value: v, label: v }));
    }
  },
  watch: {
    entity() {
      if (this.entity) {
        this.user = JSON.parse(JSON.stringify(this.entity));

        this.$emitter.emit('form.setFieldValue', { field: 'role', value: this.entity.roles });
      }
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
    this.$emitter.on('form.select.changed', this.formSelectChanged);

    if (!this.entity) {
      this.user.plainPassword = '';
    }
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
    this.$emitter.off('modal.select.changed', this.formSelectChanged);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      const user = { ...this.user };
      delete user.confirmPassword;

      const response = this.entity ? await UsersAPI.updateUser(user) : await UsersAPI.createUser(user);

      this.$emitter.emit(this.entity ? 'user.edited' : 'user.add', response.data);

      this.isSubmitting = false;
    },
    resetForm() {
      this.user.firstName = '';
      this.user.lastName = '';
      this.user.username = '';
      this.user.confirmPassword = '';
      this.user.plainPassword = '';
      this.user.active = true;
      this.user.roles = 'default';

      this.$emitter.emit('form.setFieldValue', {
        field: 'role',
        value: null
      });
    },
    modalHiddenHandler(source) {
      if (source === 'userPage') {
        this.resetForm();
      }
    },
    formSelectChanged(event) {
      if (event.field === 'role') {
        this.user.roles = event.value;
      }
    }
  }
};
</script>
