<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Part <span class="text-danger">*</span>
          </label>
          <multi-select-input
            :options="partOptions"
            name="part"
            placeholder="Select a part"
            no-results-text="No parts found"
            class="w-100 border-start-0"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Batch Number <span class="text-danger">*</span>
          </label>
          <multi-select-input
            :options="batchNumberOptions"
            name="batchNumber"
            placeholder="Select a batch Number"
            no-results-text="No batches found"
            class="w-100"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Section <span class="text-danger">*</span>
          </label>
          <multi-select-input
            :options="sectionOptions"
            name="section"
            placeholder="Select a section"
            no-results-text="No sections found"
            class="w-100"
          />
        </div>
      </div>
    </div>

    <div v-show="sectionId">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Process <span class="text-danger">*</span>
            </label>
            <multi-select-input
              :options="processOptions"
              name="process"
              placeholder="Select a process"
              no-results-text="No processes found"
              no-options-text="No processes found"
              class="w-100"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Machine <span class="text-danger">*</span>
            </label>
            <multi-select-input
              :options="machineOptions"
              name="machine"
              placeholder="Select a machine"
              no-results-text="No machines found"
              no-options-text="No machines found"
              class="w-100"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 pt-3 border-top">
      <div class="col-12">
        <div>
          <label class="form-label">
            Start Time <span class="text-danger">*</span>
          </label>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 pe-md-1">
        <div class="input-group mb-2">
          <span class="input-group-text w-25"><i class="fas fa-clock 1x" />&nbsp;H</span>
          <multi-select-input
            :options="twentyFourHourTimeOptions"
            name="startTimeHours"
            placholder="00"
            :searchable="false"
            class="w-75"
          />
        </div>
      </div>

      <div class="col-sm-12 col-md-6 ps-md-1">
        <div class="input-group">
          <span class="input-group-text w-25"><i class="fas fa-clock 1x" />&nbsp;M</span>
          <multi-select-input
            :options="fiveMinuteIntervalOptions"
            name="startTimeMinutes"
            placholder="00"
            :searchable="false"
            class="w-75"
          />
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-12">
        <div>
          <label class="form-label">
            End Time <span class="text-danger">*</span>
          </label>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 pe-md-1">
        <div class="input-group mb-2">
          <span class="input-group-text w-25"><i class="fas fa-clock 1x" />&nbsp;H</span>
          <multi-select-input
            :options="endTimeHourOptions"
            name="endTimeHours"
            placholder="00"
            :searchable="false"
            class="w-75  border-start-0"
          />
        </div>
      </div>

      <div class="col-sm-12 col-md-6 ps-md-1">
        <div class="input-group">
          <span class="input-group-text w-25"><i class="fas fa-clock 1x" />&nbsp;M</span>
          <multi-select-input
            :options="endTimeMinuteOptions"
            name="endTimeMinutes"
            placholder="00"
            :searchable="false"
            class="w-75  border-start-0"
          />
        </div>
      </div>
    </div>

    <div class="row mt-3 pt-3 border-top">
      <div class="col-sm-12 col-md-6">
        <div class="mb-3">
          <label class="form-label">
            Quantity Good <span class="text-danger">*</span>
          </label>
          <input
            v-model="lineItem.qtyGood"
            class="form-control"
            required
            type="text"
            name="qtyGood"
            placeholder="Quantity Good"
          >
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="mb-3">
          <label class="form-label">
            Quantity Scrap <span class="text-danger">*</span>
          </label>
          <input
            v-model="lineItem.qtyScrap"
            class="form-control"
            type="text"
            name="qtyScrap"
            placeholder="Quantity Scrap"
          >
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="mb-3">
          <label class="form-label">
            Minutes Lost <span class="text-danger">*</span>
          </label>
          <input
            v-model="lineItem.minutesLost"
            class="form-control"
            type="number"
            name="minutesLost"
            placeholder="Minutes Lost"
          >
        </div>
      </div>

      <div class="col-sm-6">
        <div class="mb-3">
          <div class="mesh-gear-checkbox mt-3 form-check">
            <input
              id="workedLunch"
              v-model="lineItem.workedLunch"
              type="checkbox"
            >
            <label
              class="form-check-label"
              for="workedLunch"
            >
              Worked Lunch
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label">Comments</label>
        <textarea
          v-model="lineItem.comments"
          :required="lineItem.minutesLost > 0"
          class="form-control mb-3"
          name="comments"
          placeholder="Comments"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          :disabled="disabled"
          class="btn btn-primary-dark px-4 me-2"
          type="submit"
        >
          <span v-html="buttonText" />
        </button>

        <button
          type="button"
          class="btn btn-outline-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { LineItemsAPI } from '../../api/modules';
import { fiveMinuteIntervalOptions, twentyFourHourTimeOptions } from '../../const';
import { ArrayUtils } from '../../utils';
import { MultiSelectInput } from '../shared/forms/controls';

import { DateTime } from 'luxon';

export default {
  name: 'LineItemForm',
  components: {
    MultiSelectInput
  },
  props: {
    machines: {
      type: Array,
      default: () => []
    },
    batchNumbers: {
      type: Array,
      default: () => []
    },
    sections: {
      type: Array,
      default: () => []
    },
    processes: {
      type: Array,
      default: () => []
    },
    parts: {
      type: Array,
      default: () => []
    },
    currentDate: {
      type: Date,
      default: () => null
    },
    entity: {
      type: Object,
      default: () => null
    },
    operatorId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isSubmitting: false,
      lineItem: this.entity || { qtyGood: '', qtyScrap: '', minutesLost: '', workedLunch: false },
      machineId: this.machines && this.machines.length ? this.machines[0].id : '',
      processId: this.processes && this.processes.length ? this.processes[0].id : '',
      sectionId: this.sections && this.sections.length ? this.sections[0].id : '',
      partId: this.parts && this.parts.length ? this.parts[0].id : '',
      batchNumberId: this.batchNumbers && this.batchNumbers.length ? this.batchNumbers[0].id : '',
      twentyFourHourTimeOptions,
      fiveMinuteIntervalOptions,
      endTimeHourOptions: [],
      endTimeMinuteOptions: [],
      startTimeHours: 0,
      startTimeMinutes: 0,
      endTimeHours: '00',
      endTimeMinutes: '00',
      filteredMachines: this.machines,
      filteredProcesses: this.processes
    };
  },
  computed: {
    buttonText() {
      let buttonText = '';

      if (this.isSubmitting) {
        buttonText = this.entity ? 'Saving <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Adding <i class="fas fa-spin fa-spinner ms-2"></i>';
      } else {
        buttonText = this.entity ? 'Save <i class="fas fa-save ms-2"></i>' : 'Add <i class="fas fa-plus ms-2"></i>';
      }

      return buttonText;
    },
    disabled() {
      let { qtyGood, qtyScrap, minutesLost } = this.lineItem;
      qtyGood = parseFloat(qtyGood);
      qtyScrap = parseFloat(qtyScrap);

      return (
        !this.partId || !this.batchNumberId || !this.sectionId ||
        !this.processId || this.processId === 'none' || !this.machineId || this.machineId === 'none' || qtyGood === '' ||
        qtyScrap === '' || minutesLost === '' || isNaN(qtyGood) || isNaN(qtyScrap)
      );
    },
    partOptions() {
      if (this.parts) {
        console.log(this.parts);
        return this.parts.map(part => {
          return {
            value: part.id,
            label: `${part.partNumber} - ${part.name}`
          };
        });
      }
      return [];
    },
    batchNumberOptions() {
      if (this.batchNumbers) {
        return this.batchNumbers.map(batchNumber => {
          return {
            value: batchNumber.id,
            label: `${batchNumber.name} - ${batchNumber.year}`
          };
        });
      }
      return [];
    },
    sectionOptions() {
      if (this.sections) {
        return this.sections.map(section => {
          return {
            value: section.id,
            label: section.name
          };
        });
      }
      return [];
    },
    processOptions() {
      if (this.processes) {
        return this.processes.filter(process => process.section.id === this.sectionId).map(process => {
          return {
            value: process.id,
            label: process.name
          };
        });
      }
      return [];
    },
    machineOptions() {
      if (this.machines) {
        return this.machines.filter(machine => machine.section.id === this.sectionId).map(machine => {
          return {
            value: machine.id,
            label: `${machine.name} - ${machine.serialNumber}`
          };
        });
      }
      return [];
    }
  },
  watch: {
    entity() {
      if (this.entity) {
        this.lineItem = JSON.parse(JSON.stringify(this.entity));
        this.processId = this.entity.process.id;
        this.machineId = this.entity.machine.id;
        this.sectionId = this.entity.section.id;
        this.partId = this.entity.part.id;
        this.batchNumberId = this.entity.batchNumber.id;

        const startTime = new Date(this.lineItem.startTime);
        const endTime = new Date(this.lineItem.endTime);

        this.startTimeHours = (startTime.getHours());
        this.startTimeMinutes = startTime.getMinutes();

        this.endTimeHours = (endTime.getHours()).toString().padStart(2, '0');
        this.endTimeMinutes = endTime.getMinutes().toString().padStart(2, '0');

        this.generateEndTimeHourOptions();
        this.generateEndTimeMinuteOptions();

        this.$emitter.emit('form.setFieldValue', { field: 'part', value: this.entity.part.id });
        this.$emitter.emit('form.setFieldValue', { field: 'batchNumber', value: this.entity.batchNumber.id });
        this.$emitter.emit('form.setFieldValue', { field: 'section', value: this.entity.section.id });
        this.$emitter.emit('form.setFieldValue', { field: 'process', value: this.entity.process.id });
        this.$emitter.emit('form.setFieldValue', { field: 'machine', value: this.entity.machine.id });

        const startTimeDate = new Date(this.entity.startTime);
        const endTimeDate = new Date(this.entity.endTime);

        this.$emitter.emit('form.setFieldValue', { field: 'startTimeHours', value: startTimeDate.getHours().toString() });
        this.$emitter.emit('form.setFieldValue', { field: 'startTimeMinutes', value: startTimeDate.getMinutes().toString() });
        this.$emitter.emit('form.setFieldValue', { field: 'endTimeHours', value: endTimeDate.getHours().toString().padStart(2, '0') });
        this.$emitter.emit('form.setFieldValue', { field: 'endTimeMinutes', value: endTimeDate.getMinutes().toString().padStart(2, '0') });
      }
    },
    sectionId: {
      handler() {
        this.filteredMachines = this.machines.filter(v => v.section.id === this.sectionId);
        this.filteredProcesses = this.processes.filter(v => v.section.id === this.sectionId);

        this.machineId = this.filteredMachines.length ? this.filteredMachines[0].id : null;
        this.processId = this.filteredProcesses.length ? this.filteredProcesses[0].id : null;

        this.$emitter.emit('form.setFieldValue', {
          field: 'process',
          value: this.processId
        });

        this.$emitter.emit('form.setFieldValue', {
          field: 'machine',
          value: this.machineId
        });
      }
    },
    startTimeHours() {
      this.generateEndTimeHourOptions();
      this.generateEndTimeMinuteOptions();
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
    this.$emitter.on('form.select.changed', this.formSelectChangedHandler);

    this.generateEndTimeHourOptions();
    this.generateEndTimeMinuteOptions();

    this.startTimeMinutesChanged();
    this.startTimeHoursChanged();
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
    this.$emitter.off('form.select.changed', this.formSelectChangedHandler);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      if (!this.lineItem.qtyScrap) {
        delete this.lineItem.qtyScrap;
      }

      if (!this.lineItem.minutesLost) {
        delete this.lineItem.minutesLost;
      }

      this.lineItem.machine = this.machineId;
      this.lineItem.process = this.processId;
      this.lineItem.section = this.sectionId;
      this.lineItem.part = this.partId;
      this.lineItem.batchNumber = this.batchNumberId;
      this.lineItem.operator = this.operatorId;

      if (!this.operatorId && this.$store.getters['profile/getProfile']) {
        this.lineItem.operator = this.$store.getters['profile/getProfile'].id;
      }

      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const day = this.currentDate.getDate();

      try {
        this.lineItem.startTime = new Date(year, month, day, this.startTimeHours, this.startTimeMinutes);
        this.lineItem.endTime = new Date(year, month, day, this.endTimeHours, this.endTimeMinutes);
      } catch {
        this.lineItem.startTime = new Date();
        this.lineItem.endTime = new Date();
      }

      this.lineItem.startTime = DateTime.fromJSDate(this.lineItem.startTime).toFormat('y-MM-dd HH:mm:ss');
      this.lineItem.endTime = DateTime.fromJSDate(this.lineItem.endTime).toFormat('y-MM-dd HH:mm:ss');

      const apiMethod = this.entity
        ? () => LineItemsAPI.updateLineItem(this.lineItem)
        : () => LineItemsAPI.createLineItem(this.lineItem);

      const response = await apiMethod();

      if (response) {
        this.$emitter.emit(this.entity ? 'lineItem.edited' : 'lineItem.add', response);

        await this.$alert.success(this.entity ? 'Line item edited' : 'Line item added');
      }

      this.$emitter.emit('modal.hide', { source: 'newLineItemModal' });

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.machineId = '';
      this.processId = '';
      this.sectionId = '';
      this.partId = '';
      this.batchNumberId = '';
      this.lineItem.partNumber = '';
      this.lineItem.startTime = '';
      this.lineItem.endTime = '';
      this.lineItem.minutesLost = '';
      this.lineItem.minutesLost = '';
      this.lineItem.workedLunch = 0;
      this.lineItem.qtyGood = '';
      this.lineItem.qtyScrap = '';
      this.lineItem.comments = '';
      this.startTimeHours = 0;
      this.startTimeMinutes = 0;
      this.endTimeHours = '00';
      this.endTimeMinutes = '00';

      this.$emitter.emit('form.setFieldValue', { field: 'section', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'batchNumber', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'part', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'process', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'machine', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'startTimeHours', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'startTimeMinutes', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'endTimeHours', value: null });
      this.$emitter.emit('form.setFieldValue', { field: 'endTimeMinutes', value: null });
    },
    startTimeMinutesChanged() {
      this.generateEndTimeMinuteOptions();

      if (parseInt(this.startTimeMinutes) > parseInt(this.endTimeMinutes)) {
        this.endTimeMinutes = this.endTimeMinuteOptions[0].value;
      }

      this.$emitter.emit('form.setFieldValue', {
        field: 'endTimeMinutes',
        value: this.startTimeMinutes.toString().padStart(2, '0')
      });
    },
    startTimeHoursChanged() {
      this.generateEndTimeHourOptions();

      if (parseInt(this.startTimeHours) > parseInt(this.endTimeHours)) {
        this.endTimeHours = this.endTimeHourOptions[0].value;
      }

      this.$emitter.emit('form.setFieldValue', {
        field: 'endTimeHours',
        value: this.startTimeHours.toString().padStart(2, '0')
      });
    },
    generateEndTimeHourOptions() {
      this.endTimeHourOptions = ArrayUtils
        .range(parseInt(this.startTimeHours), 24)
        .map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString().padStart(2, '0') }));
    },
    generateEndTimeMinuteOptions() {
      const start = parseInt(this.startTimeHours) === parseInt(this.endTimeHours) ? parseInt(this.startTimeMinutes) : 0;

      this.endTimeMinuteOptions = ArrayUtils
        .range(start, 60, 5)
        .map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString().padStart(2, '0') }));
    },
    modalHiddenHandler(source) {
      if (source === 'lineItemPage') {
        this.resetForm();
      }
    },
    formSelectChangedHandler(event) {
      if (event.field === 'part') {
        this.partId = event.value;
      } else if (event.field === 'batchNumber') {
        this.batchNumberId = event.value;
      } else if (event.field === 'section') {
        this.sectionId = event.value;
      } else if (event.field === 'process') {
        this.processId = event.value;
      } else if (event.field === 'machine') {
        this.machineId = event.value;
      } else if (event.field === 'startTimeHours') {
        this.startTimeHours = event.value;

        this.startTimeHoursChanged();
      } else if (event.field === 'startTimeMinutes') {
        this.startTimeMinutes = event.value;

        this.startTimeMinutesChanged();
      } else if (event.field === 'endTimeHours') {
        this.endTimeHours = event.value;

        this.startTimeMinutesChanged();
      } else if (event.field === 'endTimeMinutes') {
        this.endTimeMinutes = event.value;
      }
    }
  }
};
</script>
