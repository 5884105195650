<template>
  <form @submit.prevent="onSubmit">
    <div class="row bg-white">
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Client <span class="text-danger">*</span>
          </label>

          <input
            v-model="part.name"
            class="form-control"
            required
            type="text"
            name="name"
            placeholder="Client"
          >
        </div>
      </div>

      <div class="col-12">
        <div class="mb-3">
          <label class="form-label">
            Part Number <span class="text-danger">*</span>
          </label>
          <input
            v-model="part.partNumber"
            class="form-control"
            required
            type="text"
            name="partNumber"
            placeholder="Part Number"
          >
        </div>
      </div>

      <div class="col-12">
        <button
          class="btn btn-primary-green me-2"
          type="submit"
          :disabled="disabled"
        >
          <span v-html="buttonText" />
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { PartsAPI } from '../../api/modules';

export default {
  name: 'PartForm',
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isSubmitting: false,
      part: {
        name: '',
        partNumber: ''
      }
    };
  },
  computed: {
    buttonText() {
      let buttonText;

      if (this.isSubmitting) {
        buttonText = this.entity ? 'Saving <i class="fas fa-spin fa-spinner ms-2"></i>' : 'Adding <i class="fas fa-spin fa-spinner ms-2"></i>';
      } else {
        buttonText = this.entity ? 'Save <i class="fas fa-save ms-2"></i>' : 'Add <i class="fas fa-plus ms-2"></i>';
      }

      return buttonText;
    },
    disabled() {
      const { name, section } = this.part;

      return name === '' || section === '';
    }
  },
  watch: {
    entity() {
      this.part = JSON.parse(JSON.stringify(this.entity));
    }
  },
  created() {
    this.$emitter.on('modal.hidden', this.modalHiddenHandler);
  },
  unmounted() {
    this.$emitter.off('modal.hidden', this.modalHiddenHandler);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      const apiMethod = this.entity
        ? () => PartsAPI.updatePart(this.part)
        : () => PartsAPI.createPart(this.part);
      const response = await apiMethod();

      if (response) {
        this.$emitter.emit(this.entity ? 'part.edited' : 'part.add', response);

        await this.$alert.success(this.entity ? 'Part edited' : 'Part added');
      }

      this.isSubmitting = false;

      this.resetForm();
    },
    resetForm() {
      this.part.name = '';
      this.part.partNumber = '';
    },
    modalHiddenHandler(source) {
      if (source === 'partPage') {
        this.resetForm();
      }
    }
  }
};
</script>
