<template>
  <div>
    <Navbar />

    <div class="container container-sm flex-shrink-0 pt-3 pb-3 position-relative">
      <change-password-form style="max-width: 500px;" />
    </div>
  </div>
</template>

<script>
import { ChangePasswordForm } from '../../forms';
import { Navbar } from '../../shared';

export default {
  name: 'Login',
  components: { Navbar, ChangePasswordForm }
};

</script>

<style>
.bg-login {
  background-image: url('../../../assets/images/bg-login.jpg');
  background-size: cover;
  background-position: center;
}

.cl-logo img {
  margin-right: 10px;
  width: 50px;
}
</style>
