import { store } from '../store';

export const alertPlugin = {
  async show(type, message) {
    await store.dispatch('alert/setType', type);
    await store.dispatch('alert/setMessage', message);
  },
  async success(message) {

    await store.dispatch('alert/setType', 'success');
    await store.dispatch('alert/setMessage', message);
  },
  async danger(message) {
    await store.dispatch('alert/setType', 'danger');
    await store.dispatch('alert/setMessage', message);
  },
  async hide() {
    await store.dispatch('alert/setType', null);
    await store.dispatch('alert/setMessage', null);
  }
};

export const alert = {
  install(Vue) {
    Vue.prototype.$alert = alertPlugin;
  }
};
