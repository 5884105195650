<template>
  <div
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            New Batch Number
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <batch-number-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BatchNumberForm } from '../../../forms';

export default {
  name: 'NewBatchNumberModal',
  components: { BatchNumberForm }
};
</script>
