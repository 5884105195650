<template>
  <div class="row pt-">
    <div class="col-12">
      <ul class="pagination flex-wrap mb-5 mt-4">
        <li
          class="page-item"
          :class="{ 'disabled': page === 1 }"
          @click="handlePreviousPageClick"
        >
          <a
            class="page-link"
            href="#"
          >
            <i class="fas fa-chevron-left fa-fw" />
          </a>
        </li>

        <template
          v-for="index in pageCount"
        >
          <li
            :key="index"
            :class="{ 'active': page === index }"
            class="page-item"
            @click="handlePageChange(index)"
          >
            <a
              class="page-link"
              href="#"
            >{{ index }}</a>
          </li>
        </template>

        <li
          :class="{ 'disabled': page === pageCount }"
          class="page-item"
          @click="handleNextPageClick"
        >
          <a
            class="page-link"
            href="#"
          >
            <i class="fas fa-chevron-right fa-fw" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      pageCount: null
    };
  },
  created() {
    this.pageCount = Math.ceil(this.totalCount / this.itemsPerPage);
  },
  methods: {
    handlePageChange(page) {
      this.$emitter.emit('pagination.pageChanged', page);
    },
    handlePreviousPageClick() {
      if (this.page > 1) {
        this.handlePageChange(this.page - 1);
      }
    },
    handleNextPageClick() {
      if (this.page < this.pageCount) {
        this.handlePageChange(this.page + 1);
      }
    }
  }
};
</script>

<style scoped>

</style>
