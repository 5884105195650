import Vue from 'vue';
import Vuex from 'vuex';

import {
  alert,
  profile,
  security
} from './modules';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    pageTitle: '',
    showBackBtn: false,
    video: '6af47a3ec275106f152c1223e2aa23b7',
    currentCourseId: null
  },
  modules: {
    alert,
    security,
    profile
  },
  getters: {
  },
  mutations: {
    SET_PAGE_TITLE(state, payload) {
      state.pageTitle = payload.pageTitle;
    },
    SHOW_BACK_BTN(state, payload) {
      state.showBackBtn = payload.showBackBtn;
    },
    SET_CURRENT_COURSE_ID(state, enrolment) {
      state.currentCourseId = enrolment;
    }
  }
});

export default store;
